import types from './types.js';

const initialState = {
  isLoading: false,
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.POST_MEDIA_KIT_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    case types.POST_MEDIA_KIT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
      });
    case types.POST_MEDIA_KIT_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    default:
      return state;
  }
}
