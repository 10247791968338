import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { incrementPromo } from 'state/promo/actions';

/** usePromoViewport
 * Хук попадания рекламного баннера во vievport
 * Рекламные баннеры показываются только реальным пользователям
 * которые доскролили до него.
 * Чтобы определить попадает ли баннер во viewport использзуется
 * ref ссылка и Intersection Observer API.
 * Когда баннер попадает во viewport пользователя, отправляется запрос на
 * увеличение количества просмотров.
 *
 * @returns {array<isShow: boolean, ref: React.createRef>}
 */
export default function(id, fn = () => {}) {
  const ref = useRef();
  const dispatch = useDispatch();
  const incPromo = useCallback(id => dispatch(incrementPromo(id)), [dispatch]);
  const isLoaded = useSelector(state => state.promo.isLoaded);

  // Эффект попадания баннера во vievport
  useEffect(() => {
    const observer = new IntersectionObserver(e => {
      if (e.length && e[0].isIntersecting) {
        fn();
        observer.disconnect();
        incPromo(Number(id)); // Запрос на увеличение количества просмотров
      }
    });
    // Наблюдать элемент если указана ссылка
    if (ref.current && isLoaded) observer.observe(ref.current);

    return () => observer.disconnect();
  }, [fn, id, incPromo, isLoaded]);

  return ref;
}
