import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import { pages } from './data/data.json';
import Header from 'components/Header';
import ErrorPage from 'components/ErrorPage';
import FooterLine from 'components/FooterLine';
import BrandingPromo from 'components/promos/BrandingPromo';
import ThemeContext from 'context/ThemeContext';

import styles from './style.styl';
const cx = cn.bind(styles);

class Layout80lv extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderError: false,
    };
  }

  getHttpStatus() {
    return this.state.renderError ? 404 : this.props.httpStatus;
  }

  componentDidCatch() {
    this.setState({
      renderError: true,
    });
  }

  componentDidUpdate(_, prevState) {
    if (prevState.renderError) {
      this.setState({
        renderError: false,
      });
    }
  }

  render() {
    const { children, categories, promo } = this.props;
    const httpStatus = this.getHttpStatus();

    const headerPromoID = (promo.header && promo.header[0]) || null;
    const footerPromoID = promo.footer || [];
    return (
      <ThemeContext.Consumer>
        {({ theme: themeContext }) => (
          <React.Fragment>
            <Header pages={pages} categories={categories} />
            <main className={cx('Layout__content')}>
              {httpStatus === 200 ? (
                <React.Fragment>
                  {!['policy', 'profile'].includes(themeContext.theme) && (
                    <BrandingPromo id={headerPromoID} inHead />
                  )}
                  {children}
                </React.Fragment>
              ) : (
                <ErrorPage httpStatus={httpStatus} />
              )}
            </main>
            <FooterLine promos={footerPromoID} />
          </React.Fragment>
        )}
      </ThemeContext.Consumer>
    );
  }
}

Layout80lv.propTypes = {
  children: PropTypes.element,
  httpStatus: PropTypes.number,
  categories: PropTypes.array,
  promo: PropTypes.object,
};

const connector = connect(state => ({
  categories: state.home.categories,
  promo: state.promo.required || {},
}));

export default connector(Layout80lv);
