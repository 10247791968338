/* eslint-disable */
// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.styl';

const DEFAULT_STATE = 'Articles';

const Articles = ({ path, list, className, type = DEFAULT_STATE }) => {
  var shortRoute = path
      .split('#')[0]
      .replace(/\/?\?.*/g, '')
      .replace(/\/?/g, '');
  return (
    <div className={cn(styles.Articles, className, styles.Articles_loaded)}>
      {Boolean(list && list.length) &&
        list.map(item => {
          if (type == DEFAULT_STATE) {
            return (
              <>
                <div
                  key={item.id}
                  className={cn(styles.Articles__item, {
                    [styles.Articles__item__active]: `articles${item.slug}` === shortRoute,
                  })}
                  onClick={() => window.open(item.slug != 'all' ? `/articles/${item.slug}` : '/articles/', '_self')}
                >
                  {item.name}
                </div>
              </>
            );
          } else {
            return (
              <>
                <div
                  key={item.id}
                  className={cn(styles.Articles__item, {
                    [styles.Articles__item__active]: `${item.link}` === `/${shortRoute}`,
                  })}
                  onClick={() => window.open(item.link, (item.targetBlank ? '_blank' : '_self'))}
                >
                  {item.name}
                </div>
              </>
            )
          }
        })}
    </div>
  );
};

Articles.defaultProps = {
  path: '',
  className: '',
};

Articles.propTypes = {
  path: PropTypes.string,
  list: PropTypes.array,
  className: PropTypes.string,
};

export default Articles;
