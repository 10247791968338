import React, { Children, useState, useEffect, useCallback } from 'react';

// Components
import Slider from './';
import CounterArrows from './CounterArrows';

const SliderWithImages = ({ className, children, ...sliderParams }) => {
  const [swiper, setSwiper] = useState();
  const [currentSlide, setCurrentSlide] = useState(1);

  const goToPrev = useCallback(() => {
    if (!swiper) return false;
    swiper.slidePrev();
  }, [swiper]);

  const goToNext = useCallback(() => {
    if (!swiper) return false;
    swiper.slideNext();
  }, [swiper]);

  const slideChange = useCallback(() => {
    if (!swiper) return false;
    setCurrentSlide(swiper.realIndex + 1);
  }, [swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', slideChange);
    }
  }, [slideChange, swiper]);

  return (
    <React.Fragment>
      <Slider getSwiper={setSwiper} className={className} {...sliderParams}>
        {children}
      </Slider>
      <CounterArrows
        onPrev={goToPrev}
        onNext={goToNext}
        current={currentSlide}
        total={Children.count(children)}
      />
    </React.Fragment>
  );
};

export default SliderWithImages;
