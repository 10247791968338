import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.styl';

const Container = React.forwardRef(({ className, children }, forwardedRef) => {
  return (
    <div ref={forwardedRef} className={cn(styles.Container, className)}>
      {children}
    </div>
  );
});

Container.displayName = 'Container';

Container.defaultProps = {
  className: '',
};

Container.propTypes = {
  forwardedRef: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default Container;
