import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('home', [
  'LOADING_PAGE_START',
  'LOADING_PAGE_STOP',
  'LOADING_PAGE_FAILURE',

  'CATEGORIES_START',
  'CATEGORIES_STOP',
  'CATEGORIES_FAILURE',

  'LOADING_ARTICLES_START',
  'LOADING_ARTICLES_STOP',
  'LOADING_ARTICLES_FAILURE',

  'LOAD_FEATURED_START',
  'LOAD_FEATURED_STOP',
  'LOAD_FEATURED_FAILURE',

  'CHECK_AMP',
  'CHANGE_SLIDER_TYPE',
  'SET_SLIDERS',
]);
