import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { default as AvatarComponent } from 'ui/Avatar';
import { useImage } from 'hooks/useMedia';

export const DEFAULT_PATH = '/static/images/profile_preview_20230106.jpg';

const Avatar = ({ image, cancelDefault, ...other }) => {
  const { current } = useImage(image);

  const src = useMemo(() => {
    if (typeof current === 'string') return { src: current };

    if (current && current.src) return current;

    return cancelDefault ? '' : DEFAULT_PATH;
  }, [current, cancelDefault]);

  return <AvatarComponent image={src} {...other} />;
};

Avatar.defaultProps = {
  className: '',
  image: '',
};

Avatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  border: PropTypes.bool,
  borderColor: PropTypes.string,
  cancelDefault: PropTypes.bool,
  isBlockLabel: PropTypes.bool,
};

export default Avatar;
