import React from 'react';

const CrossIcon = props => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M.343.343a1 1 0 011.414 0L6 4.586 10.243.343a1 1 0 111.414 1.414L7.414 6l4.243 4.243a1 1 0 01-1.414 1.414L6 7.414l-4.243 4.243a1 1 0 01-1.414-1.414L4.586 6 .343 1.757a1 1 0 010-1.414z"
      fill={props.fill || '#000'}
    />
  </svg>
);

export default CrossIcon;
