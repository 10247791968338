import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';
import CrossIcon from 'features/rfp/ui/icons/CrossIcon';

import styles from './styles.styl';

const cx = cn.bind(styles);

const BaseModal = ({
  className,
  showClose = false,
  closeIcon,
  headerClassName,
  contentClassName,
  footerClassName,
  header,
  content,
  footer,
  closeModal,
  ...rest
}) => (
  <div className={cx('Popup')} {...rest}>
    <div className={cx('Popup__overlay')} onClick={closeModal} />
    <div className={cx('Popup__inner', className)}>
      {showClose && (
        <span className={cx('Popup__close')}>
          {closeIcon || <CrossIcon onClick={closeModal} />}
        </span>
      )}
      {header && <div className={cx('Popup__action', headerClassName)}>{header}</div>}
      <div className={cx('Popup__content', contentClassName)}>{content}</div>
      {footer && <div className={cx('Popup__action', footerClassName)}>{footer}</div>}
    </div>
  </div>
);

BaseModal.propTypes = {
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  header: PropTypes.node,
  footer: PropTypes.node,
  closeModal: PropTypes.func,
  content: PropTypes.node.isRequired,
  closeIcon: PropTypes.node,
  showClose: PropTypes.bool,
};

export default BaseModal;
