import types from './types';

const initialState = {
  total: 0,
  items: [],
  slug: null,
  title: null,
  isCollectedByUser: false,
  isLoading: false,
  isLoaded: false,
  isUpdating: false,
  pages: {},
  error: {},
  meta: {},
};

export default function bookmarks(state = initialState, action) {
  switch (action.type) {
    /** Article bookmarks page */
    case types.LOAD_BOOKMARKS_START:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };

    case types.LOAD_BOOKMARKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        total: action.data.collection.total,
        items: action.data.collection.items,
        slug: action.data.collection.slug,
        title: action.data.collection.title,
        isCollectedByUser: action.data.collection.isCollectedByUser,
        meta: action.data.meta,
      };

    case types.UPDATE_BOOKMARKS_REQUEST:
      return {
        ...state,
        isUpdating: true,
        error: {},
      };

    case types.UPDATE_BOOKMARKS_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        title: action.data.title,
      };

    case types.UPDATE_BOOKMARKS_FAILURE:
      return {
        ...state,
        isUpdating: false,
        error: action.error,
      };

    default:
      return state;
  }
}
