import { useState, useCallback } from 'react';

const DEFAULT_MAX_RETRIES = 1;
const noop = () => {};

export default function useRetries({ maxRetries = DEFAULT_MAX_RETRIES, fn = noop }) {
  const [retries, setRetries] = useState(0);

  const canRetry = retries < maxRetries;

  const addRetry = useCallback(() => setRetries(retries + 1), [retries]);

  const retry = useCallback(() => {
    if (fn !== noop && canRetry) {
      addRetry();
      fn();
    }
  }, [fn, canRetry, addRetry]);

  return {
    canRetry,
    addRetry,
    retry,
  };
}
