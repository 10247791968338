const { prefixRFP, rfpProfileUrl } = require('./utils');

const Routes80lv = {
  Home: { name: 'home', pattern: '/', page: 'home' },
  About: { name: 'about', pattern: '/contact-us', page: 'about' },
  Article: { name: 'articles/article', pattern: '/articles/:slug', page: 'articles/article' },
  PreviewArticle: {
    name: 'preview/articles',
    pattern: '/p/preview/:hash',
    page: 'articles/article',
  },
  Articles: { name: 'articles', pattern: '/articles', page: 'articles/category' },
  Category: { name: 'articles/category', pattern: '/articles/:slug', page: 'articles/category' },
  Workshops: { name: 'workshops', pattern: '/workshops', page: 'workshops' },
  Events: { name: 'events', pattern: '/events', page: 'events' },
  Partners: { name: 'partners', pattern: '/partners', page: 'partners' },
  Partner: { name: 'partner', pattern: '/partners/:slug', page: 'partner' },
  Bookmarks: {
    name: 'bookmarks',
    pattern: '/bookmarks/:username/:slug',
    page: 'bookmarks',
  },
  Author: { name: 'author', pattern: '/author/:slug', page: 'author' },
  JobBoard: { name: 'job-board', pattern: '/jobs', page: 'jobBoard' },
  JobBoardCard: { name: 'job-board/card', pattern: '/jobs/:slug', page: 'jobBoardCard' },
  PrivacyPolicy: { name: 'privacy-policy', pattern: '/privacy-policy', page: 'privacyPolicy' },
  TermsOfUse: { name: 'termsOfUse', pattern: '/terms-of-use', page: 'termsOfUse' },
  Disclaimer: { name: 'disclaimer', pattern: '/disclaimer', page: 'disclaimer' },
  TermsOfUseForSurvey: {
    name: 'termsOfUseForSurvey',
    pattern: '/terms-of-use-for-respondents',
    page: 'termsOfUseForSurvey',
  },
  RepublishPolicy: {
    name: 'republishing-policy',
    pattern: '/republishing-policy',
    page: 'republishingPolicy',
  },
  ProfileSettings: {
    name: 'profile/settings',
    pattern: '/profile/settings',
    page: 'profile/settings',
  },
  ProfileBookmarks: {
    name: 'profile/bookmarks',
    pattern: '/profile/bookmarks',
    page: 'profile/bookmarks',
  },
  ProfilePortfolio: {
    name: 'profile/portfolio',
    pattern: '/profile/portfolio',
    page: 'profile/portfolio',
  },
};

const RoutesRFP = {
  Home: { name: 'RFPRoute', pattern: `/${prefixRFP}/`, page: '/' },
  ArtistHome: { name: 'RFPArtistHome', pattern: `/${prefixRFP}/job-seeker/home`, page: '/artist' },
  CompaniesLanding: { name: 'RFPCompaniesLanding', pattern: `/${prefixRFP}/companies` },
  SchoolsLanding: { name: 'RFPSchoolsLanding', pattern: `/${prefixRFP}/schools` },
  SignUp: {
    name: 'RFPSignUpRoute',
    pattern: `/${prefixRFP}/registration/auth/:type`,
    page: '/registration/SignUp',
  },
  SelectRole: {
    name: 'RFPSelectRoleRoute',
    pattern: `/${prefixRFP}/registration/select-role`,
    page: '/registration/SelectRole',
  },
  ArtistInviteCode: {
    name: 'RFPArtistInviteCodeRoute',
    pattern: `/${prefixRFP}/registration/job-seeker/invite-code`,
    page: '/registration/InviteCode',
  },
  ArtistInformation: {
    name: 'RFPArtistInformationRoute',
    pattern: `/${prefixRFP}/registration/job-seeker/information`,
    page: '/registration/ArtistInformation',
  },
  CompanyInformation: {
    name: 'RFPCompanyInformationRoute',
    pattern: `/${prefixRFP}/registration/company/information`,
    page: '/registration/CompanyInformation',
  },
  DefaultArtBuilder: {
    name: 'RFPArtBuilderDefaultRoute',
    pattern: `/${prefixRFP}/job-seeker/profile/portfolio`,
    page: '/artBuilder',
  },
  DefaultPreview: {
    name: 'RFPPreviewDefaultRoute',
    pattern: `/${prefixRFP}/job-seeker/profile/preview`,
    page: '/preview',
  },
  ProfileSkills: {
    name: 'RFPProfileSkillsRoute',
    pattern: `/${prefixRFP}/job-seeker/profile/skills`,
    page: '/artist/profile/skills',
  },
  ProfileCover: {
    name: 'RFPProfileCoverRoute',
    pattern: `/${prefixRFP}/job-seeker/profile/thumbnail`,
    page: '/artist/profile/cover',
  },
  Profile: {
    name: 'RFPPublishedRoute',
    pattern: rfpProfileUrl({ domain: ':domain' }),
    page: '/published',
  },
  JobSeekerTerms: {
    name: 'RFPJobSeekerTerms',
    pattern: `/${prefixRFP}/job-seeker-terms`,
    page: '/jobSeekerTerms',
  },
  TermsOfService: {
    name: 'RFPTermsOfService',
    pattern: `/${prefixRFP}/terms-of-service`,
    page: '/termsOfService',
  },
  TermsOfUse: {
    name: 'RFPTermsOfUse',
    pattern: `/${prefixRFP}/terms-of-use`,
    page: '/termsOfUse',
  },
  Questionnaire: {
    name: 'RFPQuestionnaire',
    pattern: `/${prefixRFP}/q/:id`,
    page: '/questionnaire',
  },
  QuestionnairePreview: {
    name: 'RFPQuestionnairePreview',
    pattern: `/${prefixRFP}/jobs/:id/questionnaire/preview`,
    page: '/jobs/questionnaire/preview',
  },
  QuestionnaireEditor: {
    name: 'RFPQuestionnaireEditor',
    pattern: `/${prefixRFP}/jobs/:id/questionnaire`,
    page: '/jobs/questionnaire',
  },
  PricingPage: {
    name: 'RFPPricingPage',
    pattern: `/${prefixRFP}/pricing`,
    page: '/pricingPage',
  },
  Jobs: {
    name: 'RFPJobs',
    pattern: `/${prefixRFP}/jobs`,
    page: '/jobs',
  },
  JobResponses: {
    name: 'RFPJobResponses',
    pattern: `/${prefixRFP}/jobs/:id/responses`,
    page: '/jobResponses',
  },
  FavoriteArtists: {
    name: 'RFPFavoriteCandidates',
    pattern: `/${prefixRFP}/company/favorite-candidates`,
    page: '/favoriteArtists',
  },
  // must be last
  Category: { name: 'RFPCategoryRoute', pattern: `/${prefixRFP}/:category`, page: '/' },
};

module.exports = {
  prefixRFP,
  RoutesRFP,
  Routes80lv,
  rfpProfileUrl,
};
