const removeQueryParams = (url, paramsToOmit) => {
  const [pathname, paramsString] = url.split('?');
  const params = new URLSearchParams(paramsString);

  paramsToOmit.forEach(omitParam => params.delete(omitParam));

  let result = pathname;
  const resultParamsString = params.toString();

  if (resultParamsString.length > 0) {
    result += `?${resultParamsString}`;
  }

  return result;
};

module.exports = removeQueryParams;
