import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('author', [
  'LOADING_AUTHOR_START',
  'LOADING_AUTHOR_STOP',
  'LOADING_AUTHOR_FAILURE',

  'CHANGE_PAGE',
  'PUSH_LOADED_PAGE',
  'LOADING_ARTICLES_START',
  'LOADING_ARTICLES_STOP',
  'LOADING_ARTICLES_FAILURE',
]);
