import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
// Context
import BackgroundImage from 'ui/BackgroundImage';
import { default as ImageComponent } from 'ui/Image';
// Components
import Preloader from 'components/Preloader';
import { useImage } from 'hooks/useMedia';
// hooks
import useLazyLoad from 'hooks/useLazyLoad';
import { useRouter } from 'next/dist/client/router';

function Image({ isBg, image: data, className, isIcon, ...other }) {
  const { src, src2x, current, load, isLoaded } = useImage(data);

  const { ref } = useLazyLoad(load);
  const { route } = useRouter();

  const isRFP = route.includes('/rfpPages');

  const checkSrc = useCallback(src => (isRFP || isLoaded ? src : undefined), [isLoaded, isRFP]);

  return (
    <Preloader isLoaded={isRFP || isLoaded}>
      {({ className: preloaderClassName }) =>
        isBg ? (
          <BackgroundImage
            ref={ref}
            backgroundImage={checkSrc(current)}
            className={cn(className, { [preloaderClassName]: !isIcon })}
            {...other}
          />
        ) : (
          <ImageComponent
            ref={ref}
            src={checkSrc(src)}
            src2x={checkSrc(src2x)}
            className={cn(className, { [preloaderClassName]: !isIcon })}
            {...other}
          />
        )
      }
    </Preloader>
  );
}

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isBg: PropTypes.bool,
  isIcon: PropTypes.bool,
  onLoad: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
};

Image.defaultProps = {
  image: '',
  isIcon: false,
};

export default Image;
