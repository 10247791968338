import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import Wrapper from './Wrapper';
import ArrowIcon from 'ui/icons/ArrowIcon';

import styles from './styles.styl';

const MovingArrow = React.forwardRef(({ className }, forwardRef) => (
  <div ref={forwardRef} className={cn(styles.MovingArrow, className)}>
    <ArrowIcon className={styles.MovingArrow__icon} width={22} height={22} />
  </div>
));

MovingArrow.displayName = 'MovingArrow';

MovingArrow.defaultProps = {
  className: '',
};

MovingArrow.propTypes = {
  className: PropTypes.string,
};

MovingArrow.Wrapper = Wrapper;

export default MovingArrow;
