import { ARTICLES_TYPE } from 'snippets/latesArticles';
import types from './types';

const initialState = {
  page: 1,
  isLoadingArticles: false,

  meta: {},
  content: [],
  [ARTICLES_TYPE]: {},
  error: null,
  isLoading: false,
  isLoaded: false,
  isAmp: false,

  isLoadingCategory: false,
  isLoadedCategory: false,
  categories: [],
  tags: [],

  featured: {},
  sliders: [],
};

export default function home(state = initialState, action) {
  switch (action.type) {
    /** Загрузка страницы */
    case types.LOADING_PAGE_START:
      return Object.assign({}, state, {
        page: action.page,
        isLoading: true,
        isLoaded: false,
      });
    // break;

    case types.LOADING_PAGE_STOP:
      return Object.assign({}, state, {
        ...action.data,
        isLoading: false,
        isLoaded: true,
      });
    // break;

    case types.CHANGE_SLIDER_TYPE:
      return Object.assign({}, state, { content: action.content });

    case types.SET_SLIDERS:
      return Object.assign({}, state, { sliders: action.sliders });

    case types.CHECK_AMP:
      return {
        ...state,
        isAmp: action.payload,
      };
    // break;

    case types.LOADING_PAGE_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isLoaded: false,
        error: action.error,
      });
    // break;

    /** Категории */
    case types.CATEGORIES_START:
      return Object.assign({}, state, {
        isLoadingCategory: true,
        isLoadedCategory: false,
      });
    // break;

    case types.CATEGORIES_STOP:
      return Object.assign({}, state, {
        isLoadingCategory: false,
        isLoadedCategory: true,
        categories: action.categories,
        tags: action.tags,
      });
    // break;

    case types.CATEGORIES_FAILURE:
      return Object.assign({}, state, {
        isLoadingCategory: false,
        isLoadedCategory: false,
        error: action.error,
      });
    // break;

    /** Подгрузка статей */
    case types.LOADING_ARTICLES_START:
      return Object.assign({}, state, {
        isLoadingArticles: true,
      });
    // break;

    case types.LOADING_ARTICLES_STOP:
      return Object.assign({}, state, {
        page: action.page,
        [ARTICLES_TYPE]: Object.assign({}, state[ARTICLES_TYPE], {
          total: action.total,
          items: action.articles,
        }),
        isLoadingArticles: false,
      });
    // break;

    case types.LOADING_ARTICLES_FAILURE:
      return {
        ...state,
        errors: action.error,
        isLoadingArticles: false,
      };
    // break;

    /** Загрузка featured */
    case types.LOAD_FEATURED_START:
      return Object.assign({}, state, {
        featured: {
          isLoading: true,
          isLoaded: false,
        },
      });

    case types.LOAD_FEATURED_STOP:
      return Object.assign({}, state, {
        featured: {
          isLoading: false,
          isLoaded: true,
          ...action.data,
        },
      });

    case types.LOAD_FEATURED_FAILURE:
      return Object.assign({}, state, {
        featured: {
          isLoading: false,
          isLoaded: true,
          error: action.error,
        },
      });

    default:
      return state;
    // break;
  }
}
