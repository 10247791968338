/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.styl';

const InputField = ({
  className,
  icon,
  border,
  children,
  getRef,
  label,
  type,
  theme,
  name,
  value,
  required,
  disabled,
  invalid,
  active,
  visited,
  error,
  ...otherProps
}) => (
  <div
    className={cn(styles.InputField, className, {
      [styles[`InputField_${theme}`]]: theme,
      [styles.InputField_border]: border,
      [styles.InputField_icon]: icon,
      [styles.InputField_disabled]: disabled,
      [styles.InputField_filled]: value,
      [styles.InputField_error]: (visited && !active && invalid) || error,
    })}
  >
    {label && <label className={styles.InputField__label}>{label}</label>}
    {icon && <img className={styles.InputField__icon} src={icon} alt="alt" />}
    <input
      value={value}
      {...otherProps}
      ref={getRef}
      id={name}
      type={type}
      name={name}
      className={styles.InputField__field}
    />
    {children}
  </div>
);

InputField.defaultProps = {
  className: '',
  type: 'text',
  required: false,
};

InputField.propTypes = {
  className: PropTypes.string,
  getRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.any,
  invalid: PropTypes.bool,
  active: PropTypes.bool,
  visited: PropTypes.bool,
  error: PropTypes.string,
  theme: PropTypes.string,
};

export default InputField;
