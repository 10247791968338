import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import cn from 'classnames';

// Components
import SliderWithImages from './SliderWithImages';
import SliderWithPagination from './SliderWithPagination';
import styles from './styles.styl';

const Slider = ({ className = '', children, ...rest }) => {
  return (
    <div className={cn(styles.Slider, className)}>
      {Children.count(children) > 1 ? (
        <Swiper {...rest}>{children}</Swiper>
      ) : (
        Children.map(children, child => child)
      )}
    </div>
  );
};

Slider.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  rest: PropTypes.object,
};

Slider.SliderWithImages = SliderWithImages;
Slider.SliderWithPagination = SliderWithPagination;

export default Slider;
