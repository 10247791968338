import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './styles.styl';

const Heading = props => {
  const { weightType, font, className, level, children, ...otherProps } = props;

  const elClassname = cls(
    styles.Heading,
    styles[`Heading__font${font}`],
    styles[`Heading__${weightType}${level}`],
    className,
  );

  return React.createElement(
    `h${level}`,
    {
      ...otherProps,
      className: elClassname,
    },
    children,
  );
};

Heading.defaultProps = {
  weightType: 'semibold',
  font: 'EuclidCircularB',
};

Heading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  level: PropTypes.string.isRequired,
  className: PropTypes.string,
  font: PropTypes.oneOf(['EuclidCircularB']),
  weightType: PropTypes.oneOf(['semibold']),
};

export { Heading };
