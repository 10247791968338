import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('category', [
  'LOADING_CATEGORY_START',
  'LOADING_CATEGORY_STOP',
  'LOADING_CATEGORY_FAILURE',

  'CHANGE_PAGE',
  'PUSH_LOADED_PAGE',
  'LOADING_ARTICLES_START',
  'LOADING_ARTICLES_STOP',
  'LOADING_ARTICLES_FAILURE',
]);
