// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import AppLink from 'components/AppLink';
import styles from './styles.styl';

const Link = ({ className, isActive, children, ...otherProps }) => (
  <AppLink
    {...otherProps}
    className={cn(styles.Link, className, {
      [styles.Link_active]: isActive,
    })}
  >
    {children}
  </AppLink>
);

Link.defaultProps = {
  className: '',
};

Link.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  children: PropTypes.any,
};

export default Link;
