// Vendor
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import cn from 'classnames';

// Components
import Fade from 'components/Animations/Fade';

import styles from './styles.styl';

const Dropdown = ({ className, button, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <OutsideClickHandler onOutsideClick={handleClickOutside}>
      <div className={cn(styles.Dropdown, className)}>
        {button(toggleDropdown)}
        <Fade in={isOpen}>
          <div className={styles.Dropdown__content}>{content(toggleDropdown)}</div>
        </Fade>
      </div>
    </OutsideClickHandler>
  );
};

Dropdown.defaultProps = {
  className: '',
};

Dropdown.propTypes = {
  content: PropTypes.func,
  button: PropTypes.func,
  className: PropTypes.string,
};

export default Dropdown;
