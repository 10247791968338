/* eslint-disable */
import React from 'react';

import cn from 'classnames';
import Heading from 'components/Heading';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.styl';

export default ({ type, title, body }) => (
  <div className={styles.ToastContent}>
    <div className={cn(styles.ToastContent__header, styles[`ToastContent__header--${type}`])}>
      <Heading level={3}>{title}</Heading>
    </div>
    <div className={styles.ToastContent__body}>{body}</div>
  </div>
);
