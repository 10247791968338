import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import styles from './styles.styl';

const cx = cn.bind(styles);

const BackgroundImage = React.forwardRef(
  ({ backgroundImage, children, style, className, ...other }, ref) => (
    <div
      ref={ref}
      {...other}
      className={cx('BackgroundImage', className)}
      style={{ backgroundImage: backgroundImage && `url(${backgroundImage})`, ...style }}
    >
      {children}
    </div>
  ),
);

BackgroundImage.displayName = 'forwardRefBackgroundImage';

BackgroundImage.propTypes = {
  children: PropTypes.node,
  backgroundImage: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};

export default BackgroundImage;
