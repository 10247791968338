/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

// Custom Hooks
import usePromoData from 'hooks/usePromoData';

// Components
import AppLink from 'components/AppLink';
import Preloader from 'components/Preloader';
import Video from 'components/Video';
import Image from 'components/Image';
import useAnalyticsBanner from "../../../hooks/useAnalyticsBanner";
import styles from './styles.styl';
const cx = cn.bind(styles);

/** Рекламный баннер - Изображение с текстом */
const ImagePromo = ({ id, type, theme, className, gtmId }) => {
  const { id: promoID, type: promoType, content } = usePromoData(id, type) || {};
  const isVideo = content && /\.(mov|mp4)$/.test(content.image);

  const { handleClick, ref } = useAnalyticsBanner({
    banner_id: promoID,
    banner_type: promoType,
  });

  return (
    <div
      ref={ref}
      onClick={handleClick}
      className={cx(
        'ImgPrmo26042024',
        { [[`ImgPrmo26042024_${theme}`]]: theme, ImgPrmo26042024_hidden: !content },
        className,
      )}
    >
      {content && (
        <AppLink className={cx('ImgPrmo26042024__content')} id={gtmId} href={content.url} targetBlank>
          {isVideo ? (
            <Video
              src={content.image}
              className={cx('ImgPrmo26042024__video')}
              preload="auto"
              autoPlay="autoplay"
              loop="loop"
              muted
              playsInline
            />
            ) : (
              <Image
                image={content.image}
                className={cx('ImgPrmo26042024__image')}
              />
            )
          }
          <p className={cx('ImgPrmo26042024__label')}>
            {content.sponsored}
          </p>
        </AppLink>
      )}
    </div>
  );
};

ImagePromo.defaultProps = {
  id: null,
  type: 'image',
  className: '',
  theme: 'default',
};

ImagePromo.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  theme: PropTypes.string, // (default|article|light)
  className: PropTypes.string,
};

export default ImagePromo;
