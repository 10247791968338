import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  isLoading: false,
  isLoaded: false,
  isSliderLoading: false,
  isSliderLoaded: false,
  sliderCompanies: {},
  pages: {},
  error: {},
};

export const loadJobBoard = createAsyncThunk(
  'jobboard/load',
  async (query, { extra: httpService }) => {
    const response = await httpService.get({
      url: '/jobs',
      data: {
        sort: query.sort || 'new',
        page: query.page ? Number(query.page) : 1,
        title: query.title,
        skill: query.skill,
        tool: query.tool,
        style: query.style,
      },
    });
    return response;
  },
);

export const loadJobBoardSlider = createAsyncThunk(
  'jobboard/slider/load',
  async (_, { extra: httpService }) => {
    const response = await httpService.get({
      url: 'job-partners/slider',
    });

    return response;
  },
);

const jobBoardSlice = createSlice({
  name: 'jobBoard',
  initialState,
  extraReducers: {
    [loadJobBoard.pending]: state => {
      state.isLoading = true;
    },
    [loadJobBoard.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.isLoaded = true;
      state.data = payload;
    },
    [loadJobBoard.rejected]: (state, { error }) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = error;
    },
    [loadJobBoardSlider.pending]: state => {
      state.isSliderLoading = true;
    },
    [loadJobBoardSlider.fulfilled]: (state, { payload }) => {
      state.isSliderLoading = false;
      state.isSliderLoaded = true;
      state.sliderCompanies = payload.companies;
    },
    [loadJobBoardSlider.rejected]: state => {
      state.isSliderLoading = false;
      state.isSliderLoaded = false;
    },
  },
});

const { reducer } = jobBoardSlice;

export default reducer;
