import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BackgroundImage from '../BackgroundImage';
import { DEFAULT_PATH } from 'components/Avatar';
import style from './style.styl';

const Avatar = ({ className, size, border, borderColor, image, ...other }) => {
  const styles = cn(style.Avatar, className, {
    [style.Avatar_frame]: border,
  });

  const frameColor = borderColor || undefined;
  const sizes = size
    ? {
        width: `${size}px`,
        height: `${size}px`,
        marginTop: '',
      }
    : undefined;

  const backgroundImage = () => {
    if (typeof image === 'string') {
      return image || DEFAULT_PATH;
    }
    if (image) return image.src || DEFAULT_PATH;
  };

  return (
    <BackgroundImage
      backgroundImage={backgroundImage()}
      className={styles}
      style={{
        ...sizes,
        borderColor: frameColor,
      }}
      {...other}
    />
  );
};

Avatar.defaultProps = {
  className: '',
  image: '',
};

Avatar.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  border: PropTypes.bool,
  borderColor: PropTypes.string,
};

export default Avatar;
