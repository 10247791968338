import { useContext, useEffect } from 'react';

// Context
import ThemeContext from 'context/ThemeContext';
import getThemeByRoute from 'utils/getThemeByRoute';

/** useTheme()
 * Хук для передачи значения темы компоненту.
 *
 * @see context/ThemeContext или @see components/Layout
 * @returns {array} - {theme,setTheme}
 */
function useTheme() {
  return useContext(ThemeContext);
}

export default useTheme;

export const SetThemeByRoute = route => {
  const setTheme = useTheme().setTheme;

  useEffect(() => {
    const theme = getThemeByRoute(route);
    setTheme(theme);
  }, [route, setTheme]);
};
