import types from './types';

const initialState = {
  headerButton: {
    isLoading: false,
    isLoaded: false,
  },
  sideWidget: {
    isLoading: false,
    isLoaded: false,
  },
  barWidget: {
    isLoading: false,
    isLoaded: false,
  },
};

export default function customization(state = initialState, action) {
  switch (action.type) {
    // Header button
    case types.LOADING_HEADER_BUTTON_START:
      return Object.assign({}, state, {
        headerButton: {
          isLoading: true,
          isLoaded: false,
        },
      });
    case types.LOADING_HEADER_BUTTON_STOP:
      return Object.assign({}, state, {
        headerButton: {
          isLoading: false,
          isLoaded: true,
          ...action.data,
        },
      });
    case types.LOADING_HEADER_BUTTON_FAILURE:
      return Object.assign({}, state, {
        headerButton: {
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      });
    // Side widget
    case types.LOADING_SIDE_WIDGET_START:
      return Object.assign({}, state, {
        sideWidget: {
          isLoading: true,
          isLoaded: false,
        },
      });
    case types.LOADING_SIDE_WIDGET_STOP:
      return Object.assign({}, state, {
        sideWidget: {
          isLoading: false,
          isLoaded: true,
          ...action.data,
        },
      });
    case types.LOADING_SIDE_WIDGET_FAILURE:
      return Object.assign({}, state, {
        sideWidget: {
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      });
    // Bar widget
    case types.LOADING_BAR_WIDGET_START:
      return Object.assign({}, state, {
        barWidget: {
          isLoading: true,
          isLoaded: false,
        },
      });
    case types.LOADING_BAR_WIDGET_STOP:
      return Object.assign({}, state, {
        barWidget: {
          isLoading: false,
          isLoaded: true,
          ...action.data,
        },
      });
    case types.LOADING_BAR_WIDGET_FAILURE:
      return Object.assign({}, state, {
        barWidget: {
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      });

    default:
      return state;
  }
}
