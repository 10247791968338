import types from './types.js';

const initialState = {
  isLoading: false,
  total: 0,
  articles: [],
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_ARTICLES_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    // break;

    case types.LOAD_ARTICLES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        total: action.total,
        articles: state.articles.concat(action.articles),
      });
    // break;

    case types.LOAD_ARTICLES_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    // break;

    case types.CLEAR_ARTICLES:
      return Object.assign({}, state, {
        total: 0,
        articles: [],
        error: null,
      });
    // break;

    default:
      return state;
    // break;
  }
}
