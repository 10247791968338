import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('profile', [
  'LOAD_PROFILE_REQUEST',
  'SET_FORM_DATA',
  'SET_WORK_TYPES',
  'SET_AVATAR',
  'SET_ID',
  'LOAD_PROFILE_FAILURE',
]);
