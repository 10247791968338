import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.styl';

const Preloader = ({ className = '', children, isLoaded }) =>
  children({
    className: cn({ [styles.Preloader]: !isLoaded }, className),
  });

Preloader.propTypes = {
  isLoaded: PropTypes.any,
  children: PropTypes.func,
  className: PropTypes.string,
};

export default Preloader;
