/** state/promo */
import types from './types';

const initialState = {
  isBot: false,
  isLoaded: false,
  isLoading: false,
  isInitPay2Pay: false,

  types: ['big-image', 'branding', 'custom', 'image', 'pre-sale', 'tooltip'],
  promos: [],
  required: {},
  request: {},
  error: null,
};

export default function promo(state = initialState, action) {
  switch (action.type) {
    case types.SET_IS_BOT:
      return Object.assign({}, state, {
        isBot: action.value,
      });
    // break;

    /** Сохранение данных для запроса */
    case types.SET_REQUEST_DATA:
      return Object.assign({}, state, {
        request: action.request,
        required: action.promo,
      });
    // break;

    /** Загрузка баннеров */
    /** Начало загрузки баннеров */
    case types.LOADING_START:
      return Object.assign({}, state, {
        promos: [],
        isLoading: true,
        isLoaded: false,
      });
    // break;

    /** Успешная загрузка баннеров */
    case types.LOADING_STOP:
      return Object.assign({}, state, {
        promos: action.promos,
        isLoading: false,
        isLoaded: true,
      });
    // break;

    /** Неудачная загрузка баннеров */
    case types.LOADING_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isLoading: false,
      });
    // break;

    /** Резервирование баннера */
    case types.RESERVATION_PROMO:
      return Object.assign({}, state, {
        promos: state.promos.map(promo =>
          promo.id === action.id ? Object.assign({}, promo, { isReserved: true }) : promo,
        ),
      });

    /** Разрезервирование всех баннеров */
    case types.UNRESERVATION_PROMOS:
      return Object.assign({}, state, {
        promos: state.promos.map(promo => ({ ...promo, isReserved: false })),
      });

    /** Инициализация xsolla pay2pay виджета */
    case types.INIT_PAY_2_PAY:
      return Object.assign({}, state, {
        isInitPay2Pay: true,
      });
    // break;

    default:
      return state;
  }
}
