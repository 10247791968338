/** Добавляет уникальные id.
 * Модифицирует исходный массив.
 *
 * @param {array} ids - исходный массив
 * @param {array} list - список новых id
 */
function setIDs(ids, list) {
  list.forEach(id => {
    if (!ids.includes(id)) {
      ids.push(id);
    }
  });
}

/** Обновление баннеров.
 * Модифицирует коллекцию collection и массив ids, если в content.content есть id.
 * Увеличивает количества баннеров в коллекции, а баннеры с id записываются в массив ids.
 * Для group, вызывается рекурсия.
 *
 * @param {[number[] | string[]]} ids
 * @param {Map.<string: number>} collection
 * @param {array} content - контент страницы
 * @param {string[]} types - все типы баннеров
 */
function setContentPromos(ids, collection, content, types) {
  content.forEach(component => {
    const type = component.type;

    // Рекурсивный вызов для группы компонентов
    if (type === 'group') {
      setContentPromos(ids, collection, component.content, types);
    } else {
      // Парсинг текстового поля
      if (type === 'editor') {
        const matchDataPromoID = component.content.text.match(/data-promo-id="(\d+)"/g);
        const tooltipList =
          !matchDataPromoID || !matchDataPromoID.length
            ? []
            : matchDataPromoID.reduce((list, item) => {
                const matchRes = item.match(/\d+/);

                if (matchRes) {
                  list.push(Number(matchRes[0]));
                }

                return list;
              }, []);

        // Добавление ID  tooltype
        if (tooltipList && tooltipList.length) {
          tooltipList.forEach(id => {
            if (!ids.includes(id)) {
              ids.push(Number(id));
            }
          });
        }
      } else {
        if (types.includes(type)) {
          // Если у баннера есть id, он не записывается в коллекцию
          if (
            (component.content.id || component.content.id === 0) &&
            !ids.includes(component.content.id)
          ) {
            ids.push(component.content.id);
          } else {
            collection.set(type, collection.has(type) ? collection.get(type) + 1 : 1);
          }
        }
      }
    }
  });
}

/** NEW */
/** Добавляет юаннеры в коллекцию.
 * Модифицирует исходную коллекци.
 *
 * @param {Map.<string, number>} collection
 * @param {string} type
 * @param {number} count
 */
function addPromo(collection, type, count = 1) {
  collection.set(type, (collection.get(type) || 0) + count);
}

/** Получение id баннеров из контента и количества остальных баннеров
 * отсортированных по типам.
 *
 * @param {object} promo - id баннеров с бека
 * @param {array} content - контент страницы
 * @param {string[]} types - все типы баннеров
 * @param {object.<string: number>} required - обязательные позиции баннеров
 */
export function getPromosData(promo, content = [], types, required = {}) {
  const ids = [];
  const promos = [];
  const collection = new Map();

  // Заполнение обязательных баннеров
  Object.keys(required).forEach(block => {
    const requredTypes = required[block] ? Object.keys(required[block]) : [];
    const count = requredTypes.reduce((count, type) => required[block][type] + count, 0); // Количество обязательных

    // Проверяет контент только если есть
    if (promo[block] && promo[block].length) {
      setIDs(ids, promo[block]);
    }

    // Если установлены не все обязательные банннеры
    if (count !== 0) {
      // Если баннеры не установлены, добавить все данные из обязательных
      if (!promo[block] || !promo[block].length) {
        Object.entries(required[block]).forEach(([type, count]) =>
          addPromo(collection, type, count),
        );
      } else {
        // Если установлена часть баннеров
        if (promo[block].length < count) {
          addPromo(collection, requredTypes[0], count - promo[block].length);
        }
      }
    }
  });

  // Заполнение баннеров из контента
  setContentPromos(ids, collection, content, types);

  // Коллекцию в объект для отправки
  collection.forEach((count, type) => promos.push({ type, count }));

  return { ids, promos };
}
