// Vendor
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import cn from 'classnames';
import { withRouter } from 'next/router';

import Section from './Section';

import styles from './styles.styl';

function renderMetaTag({ type, name, content }) {
  const key = `${type}-${name}`;

  if (name === 'title') {
    return <title key={key}>{content}</title>;
  } else {
    const props = {
      key,
      [type]: name,
      content,
    };
    return <meta {...props} />;
  }
}

const Page = ({ className = '', title = '80 LEVEL', meta = {}, children, router }) => {
  const currentPath = router ? router.asPath : '/';
  const url = process.env.HOST_URL + currentPath;
  const extendedMeta = {
    'og-og:url': {
      type: 'property',
      name: 'og:url',
      content: url,
    },
    ...meta,
  };
  return (
    <Fragment>
      <Head>
        <title>{title}</title>
        {Object.keys(extendedMeta).map(key =>
          extendedMeta[key] ? renderMetaTag(extendedMeta[key]) : '',
        )}
      </Head>
      <div className={cn(styles.Page, className)}>{children}</div>
    </Fragment>
  );
};

Page.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  meta: PropTypes.object,
  router: PropTypes.object,
};

const PageWithRouter = withRouter(Page);

PageWithRouter.Section = Section;

export default PageWithRouter;
