import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router from 'next/router';
import cn from 'classnames';

import { CSSTransition } from 'react-transition-group';
import { actions } from 'state/modal/actions';
import Cross from 'static/icons/cross.svg';

import styles from './styles.styl';

class ModalContainer extends Component {
  state = {
    in: !!this.props.modalType,
    isIE: false,
  };

  componentDidMount() {
    Router.router.events.on('routeChangeStart', () => {
      if (this.props.modalType !== null) {
        this.closeModal();
      }
    });

    /* IE detect (without Edge) */
    (function() {
      window.isIe = (function() {
        var ua = window.navigator.userAgent;

        var msie = ua.indexOf('MSIE ');
        if (msie > 0) {
          // IE 10 or older => return version number
          return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
          // IE 11 => return version number
          var rv = ua.indexOf('rv:');
          return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        // other browser
        return false;
      })();
    })();
    /* /IE detect */

    if (window.isIe) {
      this.setState({ isIE: true });
    }
  }

  componentDidUpdate({ modalType: prevModalType }) {
    const { modalType } = this.props;
    // Реализовано, для того, что бы можно было открыть модалку, когда в это время открыта другая
    // CSSTransition сбрасывает css класс отображения модалки, если пропсы не изменились
    // https://github.com/reactjs/react-transition-group/blob/3d9003e9febe08442ac9c883b09ee93d6989d513/src/Transition.js#L172
    if (modalType && prevModalType !== modalType) {
      if (prevModalType) {
        this.setState({ in: false });
      }
      setTimeout(() => this.setState({ in: !!modalType }));
    }
  }

  closeModal = () => {
    const { closeModal, onClose } = this.props;
    this.setState({ in: false });
    closeModal();
    onClose();
  };

  noop = () => {};

  render() {
    const { isIE } = this.state;
    const { className, modalType: SpecificModal, modalProps = {} } = this.props;
    const { hasClose = false, theme = 'default' } = modalProps;

    const modalStyles = cn(styles.ModalContainer, className, {
      [styles[`ModalContainer_${theme}`]]: theme,
      [styles.ModalContainer_withoutBlur]: isIE,
    });

    // eslint-disable-next-line no-unused-vars
    const { closeModal, ...otherChildProps } = this.props;

    return (
      <CSSTransition in={this.state.in} unmountOnExit classNames="ModalContainer" timeout={150}>
        <div className={modalStyles}>
          <div
            className={styles.ModalContainer__overlay}
            onClick={theme !== 'search' ? this.closeModal : this.noop}
          />
          <div className={styles.ModalContainer__content}>
            <div className={styles.ModalContainer__inner}>
              {hasClose && (
                <button
                  className={cn(
                    styles.ModalContainer__close,
                    styles[`ModalContainer__close--${theme}`],
                  )}
                  onClick={this.closeModal}
                >
                  <Cross />
                </button>
              )}
              {SpecificModal && <SpecificModal closeModal={this.closeModal} {...otherChildProps} />}
            </div>
          </div>
        </div>
      </CSSTransition>
    );
  }
}

ModalContainer.defaultProps = {
  onClose: () => {},
};

ModalContainer.propTypes = {
  modalProps: PropTypes.shape({
    hasClose: PropTypes.bool,
    theme: PropTypes.string,
  }),
  className: PropTypes.string,
  closeModal: PropTypes.func,
  onClose: PropTypes.func,
  modalType: PropTypes.any,
};

ModalContainer.modal = {
  modalContainer: {},
};

const mapStateToProps = state => ({ ...state.modal });
const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch(actions.closeModal()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
