import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('likeArticles', [
  'LOAD_ARTICLES_START',
  'LOAD_ARTICLES_SUCCESS',
  'LOAD_ARTICLES_FAILURE',

  'CLEAR_DATA',
]);
