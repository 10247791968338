import { useCallback, useEffect, useRef } from 'react';
import usePortal from 'react-useportal';

import { lockScroll, unlockScroll } from 'utils/scrollLock';

import styles from './styles.styl';

const zIndexDefault = 1000;

const useModal = (zIndex = zIndexDefault, bindTo) => {
  const refBindTo = useRef(null);
  useEffect(() => {
    refBindTo.current = bindTo || document.body;
  }, [bindTo, refBindTo]);

  const { ref, isOpen, openPortal, closePortal, Portal } = usePortal({
    bindTo: refBindTo.current,
    onOpen({ portal }) {
      portal.current.classList.add(styles.Modal);
      portal.current.style.cssText = `z-index: ${zIndex};`;
    },
    onClose({ portal }) {
      portal.current.classList.remove(styles.Modal);
      portal.current.style.cssText = '';
    },
  });

  const openModal = useCallback(
    e => {
      lockScroll();
      openPortal(e);
    },
    [openPortal],
  );

  const closeModal = useCallback(
    e => {
      unlockScroll();
      closePortal(e);
    },
    [closePortal],
  );

  return {
    ref,
    isOpen,
    openModal,
    closeModal,
    Modal: Portal,
  };
};

export default useModal;
