import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('customization', [
  // Header button
  'LOADING_HEADER_BUTTON_START',
  'LOADING_HEADER_BUTTON_STOP',
  'LOADING_HEADER_BUTTON_FAILURE',
  // Side widget
  'LOADING_SIDE_WIDGET_START',
  'LOADING_SIDE_WIDGET_STOP',
  'LOADING_SIDE_WIDGET_FAILURE',
  // Bar widget
  'LOADING_BAR_WIDGET_START',
  'LOADING_BAR_WIDGET_STOP',
  'LOADING_BAR_WIDGET_FAILURE',
]);
