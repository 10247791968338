import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('job', [
  'LOAD_JOB_REQUEST',
  'LOAD_JOB_SUCCESS',
  'LOAD_JOB_FAILURE',

  'LOAD_JOBS_START',
  'LOAD_JOBS_SUCCESS',
  'LOAD_JOBS_FAILURE',

  'POST_COMMENT_START',
  'POST_COMMENT_SUCCESS',
  'POST_COMMENT_FAILURE',

  'LOAD_COMMENTS_START',
  'LOAD_COMMENTS_SUCCESS',
  'LOAD_COMMENTS_FAILURE',
  'COMMENTS_CLEAR_ERROR_FORM',

  'SEND_LIKE_START',
  'SEND_LIKE_SUCCESS',
  'SEND_LIKE_FAILURE',
]);
