import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const Portal = ({ selector, children }) => {
  const portalElement = useRef();
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    portalElement.current = document.querySelector(selector);
    setIsInit(true);

    return () => {
      setIsInit(false);
    };
  }, [selector]);

  return isInit && portalElement.current
    ? ReactDOM.createPortal(children, portalElement.current)
    : null;
};

Portal.propTypes = {
  selector: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default Portal;
