import types from './types';
import immutable from 'object-path-immutable';

const initialState = {
  data: {},
  isLoading: false,
  isLoaded: false,

  pages: {},
  isLoadingArticles: false,
  isLoadingJobs: false,

  error: {},
};

export default function partners(state = initialState, action) {
  switch (action.type) {
    /** Страница списка партнеров  */
    case types.LOAD_PARTNERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };

    case types.LOAD_PARTNERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.data,
      };

    /** Страница партнера */
    case types.LOAD_PARTNER_REQUEST:
      return immutable.set(state, ['pages', action.slug], {
        isLoading: true,
        isLoaded: false,
      });

    case types.LOAD_PARTNER_SUCCESS:
      return immutable.assign(state, ['pages', action.slug], {
        ...action.vendor,
        isLoading: false,
        isLoaded: true,
      });

    case types.LOAD_PARTNER_FAILURE:
      return Object.assign({}, state, {
        pages: immutable.set(state.pages, [action.slug], { isLoading: false }),
        error: action.error,
      });

    /** Подгрузка вакансий страницу партнера */
    case types.LOAD_JOBS_REQUEST:
      return Object.assign({}, state, {
        isLoadingJobs: true,
        error: action.error,
      });

    case types.LOAD_JOBS_SUCCESS:
      return Object.assign({}, state, {
        pages: immutable.set(state.pages, [action.slug, 'jobs'], action.data),
        isLoadingJobs: false,
      });

    case types.LOAD_JOBS_FAILURE:
      return Object.assign({}, state, {
        isLoadingJobs: false,
        error: action.error,
      });

    /** Подгрузка статей на страницу партнера */
    case types.LOAD_ARTICLES_REQUEST:
      return Object.assign({}, state, {
        isLoadingArticles: true,
      });

    case types.LOAD_ARTICLES_SUCCESS:
      return Object.assign({}, state, {
        pages: immutable.update(state.pages, [action.slug, 'articles', 'items'], items =>
          items.concat(action.items),
        ),
        isLoadingArticles: false,
      });

    case types.LOAD_ARTICLES_FAILURE:
      return Object.assign({}, state, {
        isLoadingArticles: false,
        error: action.error,
      });

    default:
      return state;
  }
}
