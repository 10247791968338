import React from 'react';
import useAuth from 'features/auth/hooks/useAuth';
import styles from './styles.styl';

function Logout() {
  const { isAuthenticated, logout } = useAuth();

  return isAuthenticated ? (
    <button type="button" className={styles.Logout__link} onClick={logout}>
      <img src="/static/icons/logout.svg" alt="logout" className={styles.Logout__linkIcon} />
    </button>
  ) : null;
}

export default Logout;
