import immutable from 'object-path-immutable';
import types from './types';

const initialState = {
  isLoading: false,
  isLoaded: false,

  meta: {},
  'header-workshop': {},
  filters: {},
  workshops: {},
  'future-events': null,
  partners: {},
  promo: {},

  activeFilters: {
    categories: [],
    schools: [],
    types: [],
  },

  errors: {},
};

export default function workshops(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_WORKSHOPS_REQUEST:
      return Object.assign({}, state, {
        ...state,
        isLoading: true,
        isLoaded: false,
        errors: {},
      });
    // break;

    case types.LOAD_WORKSHOPS_SUCCESS: {
      // console.log('data: ', action.data);

      return Object.assign({}, state, {
        isLoading: false,
        isLoaded: true,
        ...action.data,
        // activeFilters: Object.assign({}, initialState.activeFilters),
        activeFilters: action.data.activeFilters || Object.assign({}, initialState.activeFilters),
      });
    }
    // break;

    case types.LOAD_WORKSHOPS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        isLoaded: true,
        errors: action.error,
      });
    // break;

    /** Смена фильтра */
    case types.SET_FILTERS:
      return immutable.set(state, ['activeFilters', action.name], action.values);
    // break;

    default:
      return state;
  }
}
