import types from './types';

const initialState = {
  modalType: null,
  modalProps: {},
};

export default function modal(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        ...state,
        modalType: action.modalType,
        modalProps: action.modalProps,
      };

    case types.HIDE_MODAL:
      return {
        ...state,
        modalType: null,
        modalProps: {},
      };

    default:
      return state;
  }
}
