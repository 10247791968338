import React from 'react';
import PropTypes from 'prop-types';

const Form = ({ className, children, instanceRef, ...otherProps }) => (
  <form {...otherProps} ref={instanceRef} className={className}>
    {children}
  </form>
);

Form.defaultProps = {
  className: '',
};

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  instanceRef: PropTypes.func,
};

export default Form;
