import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  isLoading: false,
  isLoaded: false,
  success: false,
  error: '',
};

export const subscribeToNewsletter = createAsyncThunk(
  'subscription/subscribeToNewsletter',
  async (email, { extra: httpService }) => {
    return httpService.post({ url: 'user/subscribe', data: { email } });
  },
);

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    clearSubscribeData() {
      return initialState;
    },
  },
  extraReducers: {
    [subscribeToNewsletter.pending]: state => {
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        error: '',
      };
    },
    [subscribeToNewsletter.fulfilled]: state => {
      return {
        ...state,
        success: true,
        isLoading: false,
        isLoaded: true,
        error: '',
      };
    },
    [subscribeToNewsletter.rejected]: (state, action) => {
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        success: false,
        error: action.error.message,
      };
    },
  },
});

const { reducer, actions } = subscriptionSlice;

export const { clearSubscribeData } = actions;

export default reducer;
