const stringify = require('qs/lib/stringify');

const prefixRFP = 'talent';

const rfpProfileUrl = ({ domain, prefix = '', publishStatus }) => {
  const profileUrl = `${prefix}/${prefixRFP}/p/${domain}`;
  if (publishStatus) {
    const query = stringify({ status: publishStatus });
    const urlWithQuery = `${profileUrl}?${query}`;
    return urlWithQuery;
  }
  return profileUrl;
};

module.exports = {
  prefixRFP,
  rfpProfileUrl,
};
