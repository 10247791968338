import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { useVideo } from 'hooks/useMedia';
import Preloader from 'components/Preloader';
import useLazyLoad from 'hooks/useLazyLoad';

import styles from './styles.styl';

const Video = ({ src, className, ...other }) => {
  const { current, isLoaded, load } = useVideo(src);

  const { ref } = useLazyLoad(load);

  return (
    <Preloader isLoaded={isLoaded}>
      {({ className: preloaderClassName }) => (
        <div className={cn(styles.Video, className, preloaderClassName)} ref={ref}>
          {isLoaded && current && (
            <video className={styles.Video__video} {...other}>
              <source src={current}></source>
            </video>
          )}
        </div>
      )}
    </Preloader>
  );
};

Video.propTypes = {
  src: PropTypes.any,
  onLoad: PropTypes.func,
  className: PropTypes.string,
};

export default Video;
