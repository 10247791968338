export default async ({ promise, serverRes }) => {
  const result = await promise;

  let status =
    (result[result.length - 1] && result[result.length - 1].status) ||
    (result[result.length - 1] && result[result.length - 1].httpStatus) ||
    result.status ||
    result.httpStatus ||
    200;

  if (serverRes) {
    serverRes.statusCode = status;
  }

  const answer = {
    pageProps: result[result.length - 1],
    status,
  };

  return answer;
};
