// Регулярные выражения для проверки user-agent
const BOTS_REG_EXP = [
  'bot',
  'StackRambler',
  'yandex.(ru|com|net)',
  'mail.ru',
  'yahoo.com',
  'bing.com',
  'aol.com',
  'live.com',
  'ask.com',
  'altavista.com',
  'alexa.com',
  'lycos.com',
  'ya.ru',
  'rambler.ru',
  'aport.ru',
  'webalta.ru',
  'duckduckgo.com',
  'baidu.com',
  'Exabot',
  'exabot.com',
  'facebook.com',
  'alexa.com',

  'Sogou-Test-Spider',
  'sogou.com',

  'msn.com',
  'search.msn.com',
  'MSNBot-NewsBlogs',
  'MSNBot-Products',
  'MSNBot-Media',

  // Google
  'Googlebot-Image',
  'Googlebot-News',
  'Googlebot-Video',
  'Mediapartners-Google',
  'Adsbot-Google',
  'AdsBot-Google-Mobile-Apps',
  'google.com/(?!(?:feedfetcher.html|webmasters/answer/1061943))',
];

/** Проверяет по user-agent является ли пользователь ботом
 * @param {string} userAgent
 * @returns {boolean}
 */
export default function(userAgent) {
  return new RegExp(BOTS_REG_EXP.map(bot => `(${bot})`).join('|'), 'gi').test(userAgent);
}
