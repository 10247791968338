import nextCookie from 'next-cookies';
import cookie from 'js-cookie';

export const setGdprCookie = isAcceptGdpr => {
  const expiresDate = new Date();
  expiresDate.setMonth(expiresDate.getMonth() + 6);
  cookie.set('accept_gdpr', isAcceptGdpr, { expires: expiresDate });
};

export const removeGdprCookie = () => {
  cookie.remove('accept_gdpr');
};

export const getGdprCookie = ctx => nextCookie(ctx)['accept_gdpr'] === 'true';
