/* eslint-disable */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import Container from 'components/Container';

// Error layout
import NotFound from './NotFound';
import BadRequest from './BadRequest';
import InternalServerError from './InternalServerError';

// Custom Hooks
import usePromos from 'hooks/usePromos';

import styles from './styles.styl';

const cx = cn.bind(styles);

const REQURED_PROMOS = {
  footer: { image: 2 },
};

function ErrorPage({ httpStatus }) {
  const featured = useSelector(state => state.home.featured);

  const updatePromos = usePromos({}, null, REQURED_PROMOS, null);

  const ErrorComponent = useMemo(() => {
    return httpStatus === 404
      ? NotFound
      : httpStatus === 400
      ? BadRequest
      : httpStatus === 500
      ? InternalServerError
      : NotFound;
  }, [httpStatus]);

  return (
    <div className={cx('ErrorPage')}>
      <Container>
        <ErrorComponent httpStatus={httpStatus} featured={featured} />
      </Container>
    </div>
  );
}

ErrorPage.propTypes = {
  httpStatus: PropTypes.number,
};

export default ErrorPage;
