import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { getPromo } from 'state/promo/actions';

/** usePromoData */
export default function(id, type) {
  const dispatch = useDispatch();
  const isLoaded = useSelector(state => state.promo.isLoaded);
  const [promo, setPromo] = useState(null);

  // Сохранение зарезервированного баннера
  useEffect(() => {
    if (isLoaded) {
      setPromo(dispatch(getPromo(Number(id), type)));
    }
  }, [id, type, dispatch, isLoaded]);

  // Сброс состаяния для обновления баннеров
  useEffect(() => {
    if (!isLoaded && promo !== null) {
      setPromo(null);
    }
  }, [isLoaded, promo]);

  return promo;
}
