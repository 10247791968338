import cookie from 'js-cookie';

// Get saved comment
export const getComment = () => {
  return cookie.get('savedComment');
};

// Save user's comment on authentication
export const saveComment = comment => {
  cookie.set('savedComment', comment, { expires: 1 });
};

// Clear saved comment
export const clearComment = () => {
  cookie.remove('savedComment');
};

// Get article's slug for saved comment
export const getArticleSlug = () => {
  return cookie.get('savedArticleSlug');
};

// Save article's slug for user's comment on authentication
export const saveArticleSlug = slug => {
  cookie.set('savedArticleSlug', slug, { expires: 1 });
};

// Clear article's slug for saved comment
export const clearArticleSlug = () => {
  cookie.remove('savedArticleSlug');
};
