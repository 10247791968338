import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import Slider from 'components/Slider';
import AppLink from 'components/AppLink';
import Avatar from 'components/Avatar';
import Image from 'components/Image';
import Heading from 'components/Heading';
import Tags from 'components/Tags';
import BlockLabel from 'ui/BlockLabel';

import styles from './styles.styl';

function AssetsSlider({ className, title, image, items }) {
  const scrollbar = useMemo(
    () => ({
      el: `.${styles.AssetsSlider__scrollbar}`,
      dragClass: styles.AssetsSlider__scrollbarDrag,
      draggable: true,
      snapOnRelease: false,
    }),
    [],
  );

  return (
    <Slider
      className={cn(styles.AssetsSlider, className)}
      slidesPerView="auto"
      watchSlidesVisibility
      slideVisibleClass={styles.AssetsSlider__slide_visible}
      scrollbar={scrollbar}
    >
      <div className={cn(styles.AssetsSlider__slide, styles.AssetsSlider__slide_preview)}>
        <div className={styles.AssetsSlider__slideInner}>
          <BlockLabel>
            <Avatar
              className={styles.AssetsSlider__avatar}
              border
              cancelDefault
              borderColor="#fff"
              image={image}
            />
          </BlockLabel>

          <Heading className={styles.AssetsSlider__slideTitle} level={2}>
            {title}
          </Heading>
        </div>
      </div>

      {Boolean(items.length) &&
        items.map(item => (
          <div key={item.id} className={styles.AssetsSlider__slide}>
            <div className={styles.AssetsSlider__slideInner}>
              <Image className={styles.AssetsSlider__slideBg} image={item.image} isBg />
              <div className={styles.AssetsSlider__slideContent}>
                <Heading className={styles.AssetsSlider__slideTitle} level={3}>
                  <AppLink
                    href={`/articles/${item.slug}`}
                    className={styles.AssetsSlider__slideLink}
                  >
                    {item.title}
                  </AppLink>
                </Heading>

                <Tags
                  className={styles.AssetsSlider__slideTags}
                  theme="dark"
                  tags={item.tags.slice(0, 7)}
                  assetsPath="/articles/category"
                />
              </div>
            </div>
          </div>
        ))}
    </Slider>
  );
}

AssetsSlider.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  items: PropTypes.array,
  className: PropTypes.string,
};

AssetsSlider.defaultProps = {
  title: '',
  image: '',
  items: [],
  className: '',
};

export default AssetsSlider;
