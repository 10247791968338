export const FeatureNames = {
  Rfp: 'rfp',
  Filters: 'filters',
  Company: 'rfpCompany',
  InviteCode: 'inviteCode',
};

export const features = [
  {
    name: FeatureNames.Rfp,
    isActive: true,
  },
  {
    name: FeatureNames.Company,
    isActive: true,
  },
  {
    name: FeatureNames.InviteCode,
    isActive: true,
  },
];

export const checkIsActiveFeature = (activeFeatures = [], pageFeatures) =>
  activeFeatures.some(feature => pageFeatures.indexOf(feature) !== -1);
