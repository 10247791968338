import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import Pagination from 'components/Pagination';
import Portal from 'components/Portal';
import Slider from './index';

const SliderWithPagination = ({ className, paginationEl, onChangePage, pages, children }) => {
  const [swiper, setSwiper] = useState();
  const [currentSlide, setCurrentSlide] = useState(1);

  useEffect(() => {
    onChangePage && onChangePage(1);
  }, [onChangePage]);

  const slideTo = useCallback(
    num => {
      if (onChangePage) {
        setCurrentSlide(num);
        onChangePage && onChangePage(num);
      } else {
        swiper.slideTo(num - 1);
      }
    },
    [onChangePage, swiper],
  );

  const slideChange = useCallback(() => {
    if (!swiper) return false;
    setCurrentSlide(swiper.realIndex + 1);
  }, [swiper]);

  useEffect(() => {
    if (swiper) {
      swiper.on('slideChange', slideChange);
    }
  }, [slideChange, swiper]);

  return (
    <React.Fragment>
      <Portal selector={`#${paginationEl}`}>
        <Pagination length={pages} currentNumber={currentSlide} onClick={slideTo} />
      </Portal>
      <Slider
        getSwiper={setSwiper}
        className={className}
        simulateTouch={false}
        pagination={{
          el: '.swiper-pagination',
        }}
        dynamicBullets
      >
        {children}
      </Slider>
    </React.Fragment>
  );
};

SliderWithPagination.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.number,
  paginationEl: PropTypes.string,
  onChangePage: PropTypes.func,
  children: PropTypes.node,
};

export default SliderWithPagination;
