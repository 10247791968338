import { getDefaultMiddleware, configureStore } from '@reduxjs/toolkit';
import { mediaQueryTracker, reducer as responsive } from 'redux-mediaquery';
import breakpoints from 'config/breakpoints';

import modal from './modal';
import home from './home';
import about from './about';
import articles from './articles';
import category from './category';
import company from './company';
import events from './events';
import workshops from './workshops';
import author from './author';
import job from './job';
import jobBoardPartners from './jobBoardPartners';
import subscribe from './subscribe';
import promo from './promo';
import likeArticles from './likeArticles';
import futureEvents from './futureEvents';
import jobs from './jobs';
import jobCard from './jobCard';
import mediaKit from './mediaKit';
import auth from 'features/auth/state/auth';
import gdpr from './gdpr';
import profile from './profile';
import jobBoard from './jobBoard';
import partners from './partners';
import bookmarks from './bookmarks';
import customization from './customization';

import HttpService from 'utils/httpService';
import configureUtilService from 'utils/configuratorUtilService';

function CapitlizeString(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

const reducer = {
  responsive,
  modal,
  home,
  about,
  articles,
  category,
  job,
  jobs,
  jobBoardPartners,
  jobCard,
  company,
  events,
  partners,
  workshops,
  author,
  auth,
  gdpr,
  subscribe,
  promo,
  likeArticles,
  futureEvents,
  mediaKit,
  profile,
  jobBoard,
  bookmarks,
  customization,
};

export const initStore = (initialState = {}) => {
  const httpService = new HttpService();
  const customizedMiddleware = getDefaultMiddleware({
    thunk: {
      extraArgument: httpService,
    },
  });

  const store = configureStore({
    reducer: reducer,
    middleware: customizedMiddleware,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: initialState,
  });

  httpService.utilService = configureUtilService(store);

  const MQuery = {};
  Object.keys(breakpoints).forEach(breakpoint => {
    const query = breakpoints[breakpoint];

    MQuery[`is${CapitlizeString(breakpoint)}`] = `screen and (max-width: ${query}px)`;
  });

  store.dispatch(
    mediaQueryTracker({
      ...MQuery,
      isOnlyTablet: `screen and (min-width: ${breakpoints.phone + 1}px) and (max-width: ${
        breakpoints.tablet
      }px)`,
      innerWidth: true,
      innerHeight: true,
    }),
  );
  return store;
};
