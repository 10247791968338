import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/dist/client/router';
import getConfig from 'next/config';

import { isAcceptGdprSelector } from 'state/gdpr/selectors';
import GdprPopup from './GdprPopup';
import AnalyticsContext from '../../context/AnalyticsContext';
import { getToken, getTokenData } from 'features/auth/utils/auth';
const { publicRuntimeConfig } = getConfig();

import { GTM_ID } from 'config/google.json';
import XAConfig from 'config/xsollaAnalitics.json';

const tagManagerArgs = {
  gtmId: GTM_ID,
};

const Analytics = ({ children }) => {
  const { sub } = useMemo(() => {
    const token = getToken();
    return getTokenData(token) || {};
  }, []);
  const xsollaAnalyticsRef = useRef();

  useEffect(() => {
    const XsollaAnalytics = require('@xsolla/metrika').default;
    xsollaAnalyticsRef.current = XsollaAnalytics({
      merchantId: sub,
      server: publicRuntimeConfig.XA_SERVER,
      id: publicRuntimeConfig.COUNTER_ID,
      ...XAConfig,
    });
  }, [sub]);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  const router = useRouter();
  const isAcceptGdpr = useSelector(isAcceptGdprSelector);

  const hit = useCallback(
    (url, rest) => {
      xsollaAnalyticsRef.current &&
        xsollaAnalyticsRef.current.hit(url, {
          eid: sub,
          exv: {
            eid: sub,
            ...rest,
          },
        });
    },
    [sub],
  );

  const externalLink = useCallback(
    (name, rest) => {
      xsollaAnalyticsRef.current &&
        xsollaAnalyticsRef.current.externalLink(name, {
          eid: sub,
          exv: {
            eid: sub,
            ...rest,
          },
        });
    },
    [sub],
  );

  const elementClick = useCallback(
    (name, value, rest) => {
      xsollaAnalyticsRef.current &&
        xsollaAnalyticsRef.current.elementClick(name, {
          eid: sub,
          ev: value,
          exv: {
            eid: sub,
            ...rest,
          },
        });
    },
    [sub],
  );

  const formData = useCallback(
    (name, formDataObj, rest) => {
      xsollaAnalyticsRef.current &&
        xsollaAnalyticsRef.current.formData(name, formDataObj, {
          eid: sub,
          exv: {
            eid: sub,
            ...rest,
          },
        });
    },
    [sub],
  );

  const customEvent = useCallback(
    (name, rest) => {
      xsollaAnalyticsRef.current &&
        xsollaAnalyticsRef.current.customEvent(name, {
          eid: sub,
          exv: {
            eid: sub,
            ...rest,
          },
        });
    },
    [sub],
  );

  useEffect(() => {
    const { asPath } = router;
    hit(`https://${window.location.hostname + asPath}`, {});
  }, [router, hit]);

  const contextData = {
    hit,
    externalLink,
    elementClick,
    formData,
    customEvent,
  };

  return (
    <AnalyticsContext.Provider value={contextData}>
      {children}
      {!isAcceptGdpr && <GdprPopup />}
    </AnalyticsContext.Provider>
  );
};

Analytics.propTypes = {
  children: PropTypes.node,
};

export default Analytics;
