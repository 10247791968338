import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('events', [
  'LOAD_EVENTS_REQUEST',
  'LOAD_EVENTS_SUCCESS',

  'LOADING_EVENTS_START',
  'LOADING_EVENTS_STOP',
  'LOADING_EVENTS_FAILURE',
]);
