import types from './types';
import stringify from 'qs/lib/stringify';
import { get } from 'utils/api';
import { getPromosData } from 'utils/promos';
import promosSnippet from 'snippets/promos';

/** Пользователь (не)является ботом */
export const setIsBot = (value = false) => ({
  type: types.SET_IS_BOT,
  value,
});

/** Загрузка баннеров */
/** Начало загрузки баннеров
 * @param {object} request - данные для запроса не получение баннеров
 * @param {object} promo - позиции баннеров с id
 */
const promosSetRequesData = (request, promo) => ({
  type: types.SET_REQUEST_DATA,
  request,
  promo,
});

/** Начало загрузки баннеров */
const promosLoadingStart = () => ({
  type: types.LOADING_START,
});

/** Успешная загрузка баннеров
 * @param {object[]} promos
 */
const promosLoadingStop = promos => ({
  type: types.LOADING_STOP,
  promos,
});

/** Ошибка загрузки баннеров
 * @param {object} error
 */
const promosLoadingFailure = error => ({
  type: types.LOADING_FAILURE,
  error,
});

/** Загрузка баннеров
 * @param {object} data - данные для запроса
 */
const loadingPromos = data => dispatch => {
  dispatch(promosLoadingStart());

  return get({
    url: '/promos',
    data,
    paramsSerializer: params => stringify(params, { arrayFormat: 'indices' }),
  })
    .then(promos => dispatch(promosLoadingStop(promosSnippet(promos, data.ids))))
    .catch(error => dispatch(promosLoadingFailure(error)));
};

/** Подготовка запроса на получение баннеров
 * @param {object} promo - обязательные баннеры
 * @param {arrray} content - контент страницы
 * @param {object} required - обязательные баннеры на странице
 * @param {arrray} categories - список категорий
 * @param {arrray} vendors - вендоры страницы
 */
export const initPromos = (promo, content, required = {}, categories = [], vendors = []) => (
  dispatch,
  getState,
) => {
  const { types, isLoading } = getState().promo;

  if (!isLoading) {
    const data = Object.assign({}, getPromosData(promo, content, types, required), {
      vendors,
      categories,
    });

    return Promise.all([dispatch(promosSetRequesData(data, promo)), dispatch(loadingPromos(data))]);
  } else {
    return null;
  }
};

/** Обновление списка баннеров.
 * Действие обновляет баннеры по для активной страницы.
 * Используется везде где меняется url и не вызывается getInitialProps(пагинация или фильтры)
 */
export const updatePromos = () => (dispatch, getState) => {
  const { request } = getState().promo;

  return dispatch(loadingPromos(request));
};

/** Действие резервирующее баннер
 * @param {number | string} id
 */
export const reservePromo = id => ({
  type: types.RESERVATION_PROMO,
  id,
});

/** Действие разрезервирующее все баннеры
 * для повторного заполнения при ресайзе окна
 */
export const setUnreserveToPromos = () => dispatch => {
  return dispatch({
    type: types.UNRESERVATION_PROMOS,
  });
};

/** Действие получения баннера
 * Данное действие находит баннер по id или type,
 * резервирует его и возвращает зарезервированный баннер.
 *
 * @param {number | string} id
 * @param {string} type
 */
export const getPromo = (id, type) => (dispatch, getState) => {
  const promo = getState().promo.promos.find(
    item => (id && id === item.id) || (!id && !item.isReserved && type === item.type),
  );
  const promoID = promo ? promo.id : null;

  if (!id && promoID) {
    dispatch(reservePromo(promoID));
  }

  return promo || null;
};

/** Действие ошибки при увеличении количества баннеров */
export const incrementPromoError = error => ({
  type: types.INCREMENT_PROMO_FAILURE,
  error,
});

/** Увеличение количества просмотров баннера */
export const incrementPromo = id => (dispatch, getState) => {
  const { promo, articles } = getState();

  return id && !promo.isBot && !articles.isPreview
    ? get({ url: `/promos/${id}` })
        .then(data => {
          const error = !data.success
            ? `Ошибка! /promos/default/increment вернул статус false. ID: ${id}`
            : null;

          // обработка нестандартной ошибки
          if (error != null) {
            // eslint-disable-next-line no-console
            dispatch(incrementPromoError(data));
          }

          return data;
        })
        .catch(error => dispatch(incrementPromoError(error)))
    : null;
};

export const initPay2Pay = () => ({
  type: types.INIT_PAY_2_PAY,
});
