import MobileDetect from 'mobile-detect';

export const deviceParser = ({ headers = {} } = {}) => {
  const userAgent = headers['user-agent'] || headers['User-Agent'];
  const md = new MobileDetect(userAgent);

  const isPhone = Boolean(md.phone() || md.mobile() === 'UnknownMobile');
  const isTablet = Boolean(md.tablet() || isPhone);

  return {
    userAgent,
    isOnlyDesktop: !md.mobile(),
    isOnlyTablet: Boolean(md.tablet()),
    isTablet: isTablet,
    isTabletMedium: isTablet,
    isVerticalTablet: isTablet,
    isVerticalSmallTablet: isTablet,
    isPhone: isPhone,
    isPhoneXs: isPhone,
  };
};
