import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRole } from 'features/auth/utils/roles';
import { login as loginAction, logout as logoutAction } from 'features/auth/state/auth/actions';
import { authSelector } from 'features/auth/state/auth/selectors';

export default function useAuth() {
  const dispatch = useDispatch();
  const { isAuthenticated, token, roles, isLoaded } = useSelector(authSelector);

  const login = useCallback(token => dispatch(loginAction(token)), [dispatch]);

  const logout = useCallback(() => dispatch(logoutAction()), [dispatch]);

  const currentRole = useMemo(() => getRole(roles), [roles]);

  return {
    token,
    isAuthenticated,
    isLoaded,
    roles,
    login,
    logout,
    currentRole,
  };
}
