import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('partners', [
  'LOAD_PARTNERS_REQUEST',
  'LOAD_PARTNERS_SUCCESS',

  'LOAD_PARTNER_REQUEST',
  'LOAD_PARTNER_SUCCESS',
  'LOAD_PARTNER_FAILURE',

  'LOAD_JOBS_REQUEST',
  'LOAD_JOBS_SUCCESS',
  'LOAD_JOBS_FAILURE',

  'LOAD_ARTICLES_REQUEST',
  'LOAD_ARTICLES_SUCCESS',
  'LOAD_ARTICLES_FAILURE',
]);
