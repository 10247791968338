const nextRoutes = require('next-routes');
const routes = require('../config/routes');

const nextRouter = nextRoutes();

[...Object.values(routes.Routes80lv), ...Object.values(routes.RoutesRFP)].forEach(route => {
  nextRouter.add(route.name, route.pattern, route.page);
});

module.exports = nextRouter;
