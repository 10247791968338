import types from './types';

const initialState = {
  events: {},
  meta: [],
  promo: {},

  isLoading: false,
};

export default function events(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_EVENTS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    // break;

    case types.LOAD_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        events: action.data.events,
        meta: action.data.meta,
        promo: action.data.promo,
      };
    // break;

    // Подгрузка
    case types.LOADING_EVENTS_START:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });
    // break;

    case types.LOADING_EVENTS_STOP:
      return Object.assign({}, state, {
        isLoading: false,
        events: action.events,
      });
    // break;

    case types.LOADING_EVENTS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    // break;

    default:
      return state;
  }
}
