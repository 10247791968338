/* eslint-disable no-console */
/** Синтаксис webpack используется для поддержки node сервера */
const axios = require('axios');

function errorHandler(req = {}) {
  const errors = ['', `API Error::`];

  if (req.config) {
    errors[0] += ` ${req.config.method ? req.config.method.toUpperCase() : ''} ${req.config.url}`;
  }

  if (typeof req.params === 'object' && Object.keys(req.params).length) {
    errors.push(`Query: ${JSON.stringify(req.params)}`);
  }

  const data = (req.response && req.response.data) || null;

  if (data) {
    errors.push(`Code: ${data.status}`, `Message: ${data.name}`, data.message, '');
  }
}

// Request to API
function request(method, options) {
  const {
    url = '',
    data = {},
    before = () => {},
    success = () => {},
    error = errorHandler,
    headers = {},
    baseURL = process.env.API_URL,
    paramsSerializer = null,
  } = options;

  let params = {};

  if (method === 'get') {
    params = { ...params, ...data };
  }

  before();

  return axios({
    method,
    baseURL,
    url,
    headers,
    params,
    paramsSerializer,
    data: method === 'get' ? {} : data,
  })
    .then(response => {
      success(response.data);
      return response.data;
    })
    .catch(err => {
      error(err);
      return Promise.reject(
        (err.response && err.response.data) || {
          success: false,
          message: 'Server error',
        },
      );
    });
}

// Request method aliases
module.exports = {
  default: request,
  get: params => request('get', params),
  post: params => request('post', params),
  put: params => request('put', params),
  patch: params => request('patch', params),
  del: params => request('delete', params),
};
