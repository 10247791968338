const jwtDecode = require('jsonwebtoken/decode');
const nextCookie = require('next-cookies');
const cookie = require('js-cookie');

const getCookieDomain = require('../../../utils/url/getCookieDomain');
const toTimestamp = strDate => Date.parse(strDate) / 1000;
const getTokenData = token => jwtDecode(token) || {};

const setToken = async ({ token }) => {
  const { exp } = getTokenData(token);
  const domain = getCookieDomain(window.location.hostname);
  cookie.set('token', token, { domain, expires: new Date(exp * 1000) });
};

const removeToken = () => {
  const domain = getCookieDomain(window.location.hostname);
  cookie.remove('token', { domain });
  cookie.remove('token');
};

const getTokenFromCtx = ctx =>
  (ctx.query.token || nextCookie(ctx).token || '').replace(/^"|"$/g, '');

const getToken = ctx => (ctx ? getTokenFromCtx(ctx) : cookie.get('token'));

const getUserRolesFromTokenGroups = (groups = []) => groups.map(item => item.name);

const isTokenExpires = token => {
  const { exp } = getTokenData(token);
  return exp < toTimestamp(new Date());
};

module.exports = {
  getTokenData,
  setToken,
  removeToken,
  getToken,
  getUserRolesFromTokenGroups,
  isTokenExpires,
};
