import immutable from 'object-path-immutable';
import { ARTICLES_TYPE } from 'snippets/latesArticles';
import types from './types';

const initialState = {
  page: 1,
  sort: 'new',
  isLoadingArticles: false,
  sortOptions: [
    { value: 'new', label: 'New' },
    { value: 'popular', label: 'Popular' },
    { value: 'discussed', label: 'Most discussed' },
  ],

  pages: {},
  errors: {},
};

export default function author(state = initialState, action) {
  switch (action.type) {
    /** Загрузка страницы */
    case types.LOADING_AUTHOR_START:
      return Object.assign({}, state, {
        page: action.page,
        sort: action.sort,
        pages: immutable.set(state.pages, action.author, {
          isLoading: true,
          isLoaded: false,
        }),
      });
    // break;

    case types.LOADING_AUTHOR_STOP:
      return Object.assign({}, state, {
        pages: immutable.set(state.pages, action.author, {
          ...action.data,
          isLoading: false,
          isLoaded: true,
        }),
      });
    // break;

    case types.LOADING_AUTHOR_FAILURE:
      return Object.assign({}, state, {
        pages: immutable.set(state.pages, action.author, {
          isLoading: false,
          isLoaded: false,
        }),
        errors: Object.assign({}, state.errors, {
          [action.author]: action.error,
        }),
      });
    // break;

    /** Смена номера страницы */
    case types.CHANGE_PAGE:
      return Object.assign({}, state, {
        page: action.page,
        sort: action.sort,
      });
    // break;

    /** Добавляет номер загруженной страницы */
    case types.PUSH_LOADED_PAGE:
      return immutable.push(
        state,
        ['pages', action.author, 'loadedPages', action.sort],
        action.page,
      );
    // break;

    /** Подгрузка статей */
    case types.LOADING_ARTICLES_START:
      return Object.assign({}, state, {
        isLoadingArticles: true,
      });
    // break;

    case types.LOADING_ARTICLES_STOP:
      return Object.assign({}, state, {
        page: action.page,
        sort: action.sort,
        pages: immutable.assign(state.pages, [action.author, ARTICLES_TYPE], action.latesArticle),
        isLoadingArticles: false,
      });
    // break;

    case types.LOADING_ARTICLES_FAILURE:
      return {
        ...state,
        errors: Object.assign({}, state.errors, {
          [action.author]: action.error,
        }),
        isLoadingArticles: false,
      };
    // break;

    default:
      return state;
  }
}
