import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadProfile, updateProfile as update } from 'state/profile/actions';
import { workTypesSelector, avatarSelector, formDataSelector } from 'state/profile/selectors';
import useAuth from 'features/auth/hooks/useAuth';
import useRetries from './useRetries';

export default function useProfile() {
  const dispatch = useDispatch();
  const { token } = useAuth();
  const { isLoaded, isLoading, id } = useSelector(state => state.profile);

  const avatar = useSelector(avatarSelector);
  const workTypes = useSelector(workTypesSelector);
  const formData = useSelector(formDataSelector);

  const reduxLoadProfile = useCallback(() => dispatch(loadProfile()), [dispatch]);
  const updateProfile = useCallback(data => dispatch(update(data)), [dispatch]);
  const { retry: retryLoadProfile } = useRetries({ fn: reduxLoadProfile });

  useEffect(() => {
    token && !isLoaded && !isLoading && retryLoadProfile();
  }, [dispatch, isLoaded, isLoading, token, retryLoadProfile]);

  return {
    avatar,
    workTypes,
    isLoading,
    isLoaded,
    formData,
    updateProfile,
    id,
  };
}
