/** Returns URL query from query object.
 * @param {object} query
 * @return {string}
 */

const mapQuery = query => {
  const queryEntries = Object.entries(query);

  return queryEntries.reduce(
    (queryStr, entry, index) => `${queryStr}${index > 0 ? '&' : ''}${entry[0]}=${entry[1]}`,
    '',
  );
};

/** Возвращает ссылку из URL и параметров query.
 * @param {string} url
 * @param {object} query
 */
export default function(url, query) {
  const queryString = Object.keys(query).length > 0 ? mapQuery(query) : '';
  const isExistQuery = /\?/.test(url);

  return url + (isExistQuery ? '&' : '?') + queryString;
}
