import React from 'react';

/** Контекст для передачи темы.
 * Используется с хуком useTheme.
 * Первый аргумент - данные, а второй функция для изменения
 */
export default React.createContext([
  {
    theme: 'default',
    bgImage: null,
    bgColor: null,
    textColor: null,
    accentColor: null,
    textAccentColor: null,
    palette: { main: 'black' },
  },
  () => {},
]);
