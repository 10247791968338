import types from './types.js';

const initialState = {
  isLoading: false,
  total: 0,
  items: [],
  error: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_EVENTS_START:
      return Object.assign({}, state, {
        isLoading: true,
      });
    // break;

    case types.LOAD_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        total: action.total,
        items: state.items.concat(action.items),
      });
    // break;

    case types.LOAD_EVENTS_FAILURE:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    // break;

    default:
      return state;
    // break;
  }
}
