import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Wrapper = ({ children }) =>
  React.cloneElement(children, {
    className: cn(children.props.className, 'MovingArrowWrapper'),
  });

Wrapper.defaultProps = {
  children: {},
};

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Wrapper;
