function getCookieDomain(host) {
  const domain = host.split(':')[0];
  const domainMatch = `.${domain}`.match(/(\.\w+){2}$/);
  const isOneLevel = domainMatch === null;
  if (!isOneLevel) return domainMatch[0];
  return domain;
}

// Use CommonJS for this file as Node use it without transpiling
module.exports = getCookieDomain;
