/* eslint-disable */
// Vendor
import React, { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { actions } from 'state/modal/actions';
import { debounce } from 'utils/debounce';

// Components
import Container from 'components/Container';
import Button from 'components/Button';
import Link from '../Link';
import Logout from '../Logout';
import AppLink from 'components/AppLink';

// Helpers
import { lockScroll } from 'utils/scrollLock';

// Hooks
import useAuth from 'features/auth/hooks/useAuth';
import useLogin from 'features/auth/hooks/useLogin';
import useWasUpdated from 'hooks/useWasUpdated';
import useEventListener from 'hooks/useEventListener';

import styles from './styles.styl';

const DEFAULT_STATE = 'Articles';

const Menu = ({
  className,
  pages,
  isShow,
  tags,
  path,
  onClose,
  orderOutsourcingButton,
  openSearch,
}) => {
  const [innerPages, setInnerPages] = useState(pages);
  const dispatch = useDispatch();
  const closeModal = useCallback(() => dispatch(actions.closeModal()), [dispatch]);
  const isUpdate = useWasUpdated();
  const { isAuthenticated } = useAuth();
  const { showFullscreenWidget } = useLogin();
  useEffect(() => {
    if (isUpdate) {
      isShow ? lockScroll() : closeModal();
    }

    return () => !isShow && closeModal();
  }, [closeModal, isShow, isUpdate]);

  const submenusSwitching = useCallback(() => {
    if (!tags) return;
    var newTags = [...tags]
      .map(newTag => {
        return {
          numLine: Math.ceil(newTag.name.length / 19), // 19 is maximum number of character for mobile view (max-width 360px)
          strlen: newTag.name.length,
          ...newTag,
        };
      })
      .sort((newTagA, newTagB) => {
        if (
          newTagB.slug === 'all' ||
          newTagB.slug === 'news' ||
          newTagA.numLine === newTagB.numLine
        )
          return 0;
        if (newTagA.numLine < newTagB.numLine) return 1;
        if (newTagA.numLine > newTagB.numLine) return -1;
      })
      .sort((newTagA, newTagB) => {
        if (newTagB.slug === 'all' || newTagB.slug === 'news' || newTagA.strlen === newTagB.strlen)
          return 0;
        if (newTagA.strlen < newTagB.strlen) return 1;
        if (newTagA.strlen > newTagB.strlen) return -1;
      });
    const updatePages = pages.map(page => {
      if (page.name === DEFAULT_STATE) {
        page.children = newTags;
      }
      return page;
    });
    setInnerPages(updatePages);
  }, [tags]);

  useEffect(() => {
    submenusSwitching();
  }, [submenusSwitching]);

  useEventListener('resize', debounce(submenusSwitching, 50));

  const toggleCurrentArrow = (itemId, isOpened = false) => {
    var localPages = innerPages.map(page => {
      if (itemId === page.id) {
        page.isOpened = isOpened || !page.isOpened;
      }
      return page;
    });
    setInnerPages(localPages);
  };

  useEffect(() => {
    var shortRoute = path
      .split('#')[0]
      .replace(/\/?\?.*/g, '')
      .replace(/\/?/g, '');
    const updatePages = pages.map(page => {
      if (page.name === DEFAULT_STATE) {
        page.children = tags;
      }
      if (page.link === `/${shortRoute}`) {
        if (page.children) {
          toggleCurrentArrow(page.id, true);
        }
      } else if (page.children && page.children.length > 0) {
        page.children.map(pagechild => {
          if (pagechild.link === `/${shortRoute}`) {
            toggleCurrentArrow(page.id, true);
          }
          return pagechild;
        });
      }
      return page;
    });
    setInnerPages(updatePages);
  }, []);

  return (
    <Fragment>
      <div className={cn(styles.Menu, className, { [styles.Menu_show]: isShow })}>
        <div className={styles.Menu__section}>
          <Container className={styles.Menu__topLevel}>
            <div className={styles.Menu__header}>
              <Link href="/" className={styles.Menu__headerTitle} onClick={onClose}>
                <img src="/static/icons/logo80lv.svg" alt="logo80lv" />
              </Link>

              {orderOutsourcingButton}

              <Button className={styles.Menu__closeBtn} theme="none" onClick={onClose}>
                <img
                  src="/static/icons/close_new.svg"
                  alt="Close"
                  className={styles.Menu__closeIcon}
                />
              </Button>
            </div>
          </Container>
        </div>

        <div className={styles.Menu__content}>
          <div className={styles.Menu__section}>
            <div className={styles.Menu__pages}>
              {innerPages.map(item => (
                <>
                  <div
                    key={item.id}
                    className={cn(styles.Menu__pagesItem, {
                      [styles.Menu__pagesItem__active]: item.children && item.isOpened,
                    })}
                    onClick={() => {
                      if (item.children) {
                        toggleCurrentArrow(item.id);
                      } else {
                        window.open(item.link, item.targetBlank ? '_blank' : '_self');
                        onClose();
                      }
                    }}
                  >
                    {item.name}
                    {item.children && (
                      <img
                        src={`/static/icons/arrow-${item.isOpened ? 'up-active' : 'down'}.svg`}
                        alt="click_arrow"
                        className={styles.Menu__pagesItem__Arrow}
                      />
                    )}
                  </div>

                  <div
                    className={cn(styles.Menu__articles, {
                      [styles.Menu__articles__Haschild]: item.isOpened,
                    })}
                  >
                    {item.isOpened &&
                      item.children.map((childItem, childItemIndex) => {
                        if (item.name === DEFAULT_STATE) {
                          return (
                            <div
                              key={childItem.id}
                              className={cn(styles.Menu__articlesItem, {
                                [styles.Menu__articlesItem__notLast]:
                                  childItemIndex < item.children.length - 2,
                              })}
                            >
                              <div
                                className={styles.Menu__articlesLink}
                                isActive={`/articles/${childItem.slug}/` === path.split('#')[0]}
                                onClick={() => {
                                  window.open(
                                    childItem.slug != 'all'
                                      ? `/articles/${childItem.slug}`
                                      : '/articles/',
                                    '_self',
                                  );
                                  onClose();
                                }}
                              >
                                {childItem.name}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <div key={childItem.id} className={styles.Menu__articlesItem}>
                              <div
                                className={styles.Menu__articlesLink}
                                isActive={childItem.link === path.split('#')[0]}
                                onClick={() => {
                                  window.open(
                                    childItem.link,
                                    childItem.targetBlank ? '_blank' : '_self',
                                  );
                                  onClose();
                                }}
                              >
                                {childItem.name}
                              </div>
                            </div>
                          );
                        }
                      })}
                  </div>
                </>
              ))}

              <div
                key={9}
                className={styles.Menu__pagesItem}
                onClick={() => {
                  window.open('/contact-us/#Promote', '_self');
                  onClose();
                }}
              >
                Advertise
                <img
                  width="20"
                  height="20"
                  src="/static/icons/player_new.svg"
                  className={styles.Menu__pagesItem__Arrow}
                  alt="player"
                />
              </div>
              <div className={styles.Menu__articles}></div>

              <div
                key={10}
                className={styles.Menu__pagesItem}
                onClick={() => {
                  onClose();
                  setTimeout(openSearch, 50);
                }}
              >
                Search
                <img
                  src="/static/icons/search24px.svg"
                  alt="search"
                  className={styles.Menu__pagesItem__Arrow}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.Menu__section}>
          <div className={styles.Menu__footer}>
            {isAuthenticated ? (
              <>
                <div className={styles.Menu__footerWrapper}>
                  <AppLink
                    className={styles.Menu__footerItem}
                    onClick={onClose}
                    href="/profile/settings"
                  >
                    <img
                      src="/static/icons/profile-login.svg"
                      alt="profile-loggedin"
                      className={styles.Menu__pagesItem__Icon}
                    />
                    <span className={styles.Menu__footerProfile}>Profile</span>
                  </AppLink>
                  <AppLink
                    className={styles.Menu__footerItem}
                    onClick={onClose}
                    href="/profile/bookmarks"
                  >
                    <img
                      src="/static/icons/bookmark.svg"
                      alt="bookmark"
                      className={styles.Menu__pagesItem__Icon}
                    />
                    <span className={styles.Menu__footerProfile}>Bookmarks</span>
                  </AppLink>
                </div>
                <Logout />
              </>
            ) : (
              <>
                <div className={styles.Menu__footerItem} onClick={showFullscreenWidget}>
                  <img
                    src="/static/icons/profile-login.svg"
                    alt="profile-login"
                    className={styles.Menu__pagesItem__Icon}
                  />
                  <span className={styles.Menu__footerLogin}>LogIn</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={cn(styles.MenuLayer, { [styles.MenuLayer_show]: isShow })} />
    </Fragment>
  );
};

Menu.defaultProps = {
  className: '',
};

Menu.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array,
  isShow: PropTypes.bool,
  path: PropTypes.string,
  onClose: PropTypes.func,
  isTablet: PropTypes.bool,
  isPhone: PropTypes.bool,
  orderOutsourcingButton: PropTypes.object,
  openSearch: PropTypes.func,
};

export default Menu;
