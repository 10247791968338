import types from './types';
import { get } from 'utils/api';
import { homeInitialSnippet, homeLoadingSnippet } from 'snippets/latesArticles';

export const setSliderType = type => (dispatch, getState) => {
  const data = getState().home;
  const content = [...data.content];

  const sliderContentDataIndex = content.findIndex(item => item.type === 'slider');
  const { type: sliderCurrentType, ...sliderCurrentTypeData } =
    data.sliders.find(item => item.type === type) || data.sliders[0];

  content.splice(sliderContentDataIndex, 1, {
    type: sliderCurrentType,
    content: sliderCurrentTypeData,
  });

  return dispatch({
    type: types.CHANGE_SLIDER_TYPE,
    content,
  });
};

const setSliders = sliders => ({
  type: types.SET_SLIDERS,
  sliders,
});

const loadCategoriesStart = () => ({
  type: types.CATEGORIES_START,
});

/** Успешная зкгрузка категорий
 * @param {array} categories
 * @param {array} tags
 */
export const loadCategoriesSuccess = (categories, tags) => ({
  type: types.CATEGORIES_STOP,
  categories,
  tags,
});

/** Ошибка загрузки
 * @param {object} error
 */
const loadCategoriesFailrule = categories => ({
  type: types.CATEGORIES_FAILURE,
  categories,
});

// Загрузка категорий
const loadCategories = () => dispatch => {
  dispatch(loadCategoriesStart());

  return get({ url: '/main/layout' })
    .then(data => dispatch(loadCategoriesSuccess(data.menu, data.tags)))
    .catch(error => {
      dispatch(loadCategoriesFailrule(error));

      return Promise.reject(error);
    });
};

// Загрузка категорий если они не загружены
export const loadCategoriesIfNotLoaded = () => (dispatch, getState) => {
  const { isLoadingCategory, isLoadedCategory } = getState().home;

  if (!isLoadingCategory && !isLoadedCategory) {
    return dispatch(loadCategories());
  } else {
    return false;
  }
};

/** Загрузка страницы */
/** Загрузка данных для главной страницы
 * @param {number} page - номер страницы
 * @returns {object}
 */
const loadPageStart = page => ({
  type: types.LOADING_PAGE_START,
  page,
});

/** Успешная загрузка данных
 * @param {object} data
 * @returns {object}
 */
const loadPageStop = data => ({
  type: types.LOADING_PAGE_STOP,
  data,
});

/** Ошибка загрузки данных
 * @param {object} error
 * @returns {object}
 */
const loadPageFail = error => ({
  type: types.LOADING_PAGE_FAILURE,
  error,
});

/** Запрос на получение данных
 * @param {number} page - номер страницы
 * @returns {Promise}
 */
export const loadPage = page => (dispatch, getState) => {
  dispatch(loadPageStart(page)); // Начало загрузки

  const { profile } = getState();

  const consumerId = profile.id;
  const headers = consumerId ? { ['X-Consumer']: consumerId } : {};

  return get({ url: '/page/main', data: { page }, headers })
    .then(async data => {
      const sliders = data.content.find(item => item.type === 'slider');
      await dispatch(setSliders(sliders ? sliders.content : []));
      return await dispatch(loadPageStop(homeInitialSnippet(data, page)));
    })
    .catch(error => {
      dispatch(loadPageFail(error));
      return Promise.reject(error);
    });
};

/** Загрузка страницы если она не загружена
 * @param {number} page - номер страницы
 * @returns {Promise}
 */
export const loadPageIfNotLoaded = page => (dispatch, getState) => {
  const { loadedPages, isLoaded, isLoading } = getState().home;
  const isExistPage = loadedPages && loadedPages.includes(page);

  if (!isLoaded && !isLoading && !isExistPage) {
    return dispatch(loadPage(page));
  } else {
    return null;
  }
};

/** Действие начала подгрузки статей
 * @param {number} page - новая страница
 */
const startLoadArticle = page => ({
  type: types.LOADING_ARTICLES_START,
  page,
});

/** Действие удачной подгрузки статей
 * @param {number} page - новая страница
 * @param {object} articles
 * @param {number} total
 */
const stopLoadArticle = (page, articles, total) => ({
  type: types.LOADING_ARTICLES_STOP,
  page,
  articles,
  total,
});

/** Действие ошибки подгрузки статей
 * @param {error} error
 */
const failureLoadArticle = error => ({
  type: types.LOADING_ARTICLES_FAILURE,
  error,
});

/** Подгрузка статей в категорию
 * @param {number} page
 * @param {number} total
 */
const getArticles = (page, total) => (dispatch, getState) => {
  dispatch(startLoadArticle(page)); // Начало загрузки

  const { profile } = getState();

  const consumerId = profile.id;
  const headers = consumerId ? { ['X-Consumer']: consumerId } : {};

  return get({
    url: '/articles/list',
    data: { page, total },
    headers,
  })
    .then(data => {
      const latestArticles = homeLoadingSnippet(page, data);

      return dispatch(stopLoadArticle(page, latestArticles.items, latestArticles.total));
    })
    .catch(error => dispatch(failureLoadArticle(error)));
};

/** Подгрузка статей.
 * Данное действие запускается только после загрузки данных
 * страницы автора - loadAuthorIfNotLoaded()
 *
 * @param {number} page
 */
export const loadArticles = page => (dispatch, getState) => {
  const home = getState().home;
  const TOTAL = 9;

  // Проверяет, идет ли сейчас загрузка и были ли
  // загружены статьи для запрашиваемой страницы
  if (!home.isLoadingArticles) {
    return dispatch(getArticles(page, TOTAL));
  } else {
    return null;
  }
};

// Загрузка блока Featured для 404 страницы
const loadFeaturedStart = () => ({
  type: types.LOAD_FEATURED_START,
});

/** Успешная загрузка
 * @param {object} data
 */
export const loadFeaturedSuccess = data => ({
  type: types.LOAD_FEATURED_STOP,
  data,
});

/** Ошибка загрузки
 * @param {object} error
 */
const loadFeaturedFailure = error => ({
  type: types.LOAD_FEATURED_FAILURE,
  error,
});

const loadFeatured = () => dispatch => {
  dispatch(loadFeaturedStart());

  return get({ url: '/main/featured' })
    .then(data => dispatch(loadFeaturedSuccess(data)))
    .catch(error => dispatch(loadFeaturedFailure(error)));
};

// Загрузка блока featured если он не загружен
export const loadFeaturedIfNotLoaded = () => (dispatch, getState) => {
  const { featured } = getState().home;
  const { isLoading, isLoaded } = featured;

  if (!isLoading && !isLoaded) {
    return dispatch(loadFeatured());
  } else {
    return false;
  }
};

export const checkAmp = payload => {
  return {
    type: types.CHECK_AMP,
    payload,
  };
};
