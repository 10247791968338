import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clearComment, clearArticleSlug } from 'utils/comments';

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: {},
  pages: {},
  postComments: false,

  isLoadingPreview: false,
  isLoadedPreview: false,
  isFailrulePreview: false,
  preview: {},

  isPreview: false,

  error: null,
  errors: {
    article: null,
    preview: null,
    interesting: null,
    comments: {
      form: null,
      items: null,
    },
  },
};

export const loadArticleBySlug = createAsyncThunk(
  'articles/loadBySlug',
  async (slug, { extra: httpService, getState }) => {
    const { articles, profile } = getState();
    const { isLoaded, isLoading } = articles.pages[slug] || {};

    if (isLoaded && isLoading) {
      return null;
    }
    const consumerId = profile.id;
    const headers = consumerId ? { ['X-Consumer']: consumerId } : {};

    const article = await httpService.get({ url: `/articles/${slug}`, headers });
    return article;
  },
);

export const postComment = createAsyncThunk(
  'articles/postComment',
  async ({ slug, data }, { extra: httpService, getState }) => {
    const article = getState().articles.pages[slug];
    const response = await httpService.post({ url: `/comments/post/${article.id}`, data });

    clearArticleSlug();
    clearComment();

    return { comment: response.message, total: article.comments.total + 1 };
  },
);

export const loadComments = createAsyncThunk(
  'articles/loadComments',
  async ({ slug, offset }, { extra: httpService, getState }) => {
    const article = getState().articles.pages[slug];

    const response = await httpService.get({
      url: `/comments/more/post/${article.id}`,
      data: {
        offset,
        total: 10,
      },
    });
    return response;
  },
);

export const sendLike = createAsyncThunk(
  'articles/sendLike',
  async ({ slug, commentId, data }, { extra: httpService, getState }) => {
    const article = getState().articles.pages[slug];
    const commentIndex = article.comments.items.findIndex(({ id }) => id === commentId);
    const comment = article.comments.items[commentIndex];
    const likeStatus = comment.like === data.action ? null : data.action;

    const response = await httpService.post({ url: `/comments/like/${commentId}`, data });
    return { karma: response.message.karma, likeStatus };
  },
);

export const loadPreview = createAsyncThunk(
  'articles/loadPreview',
  async (hash, { extra: httpService }) => {
    const response = await httpService.get({ url: `/articles/preview/${hash}` });
    return response;
  },
);

const articlesSlice = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    commentsClearErrorForm(state) {
      state.errors.comments.form = null;
    },
    clearPreview(state) {
      state.isPreview = false;
    },
  },
  extraReducers: {
    [loadArticleBySlug.pending]: (state, { meta: { arg: slug } }) => {
      state.pages[slug] = {
        isLoading: true,
        isLoaded: false,
        errors: initialState.errors,
      };
    },
    [loadArticleBySlug.fulfilled]: (state, { payload, meta: { arg: slug } }) => {
      state.pages[slug] = payload;
      state.pages[slug].isLoading = false;
      state.pages[slug].isLoaded = true;
    },
    [loadArticleBySlug.rejected]: (state, { meta: { arg: slug }, error }) => {
      state.pages[slug].isLoaded = false;
      state.pages[slug].isLoading = false;
      state.pages[slug].errors.article = error;
    },
    [postComment.pending]: state => {
      state.postComments = true;
    },
    [postComment.fulfilled]: (
      state,
      {
        meta: {
          arg: { slug },
        },
        payload: { comment, total },
      },
    ) => {
      state.postComments = false;
      state.pages[slug].comments.items.unshift(comment);
      state.pages[slug].comments.total = total;
    },
    [postComment.rejected]: (state, { error }) => {
      state.postComments = false;
      state.errors.comments.form = error;
    },
    [loadComments.pending]: (
      state,
      {
        meta: {
          arg: { slug },
        },
      },
    ) => {
      state.pages[slug].comments.isLoading = true;
      state.pages[slug].comments.isLoaded = false;
      state.errors.comments.items = null;
    },
    [loadComments.fulfilled]: (
      state,
      {
        meta: {
          arg: { slug },
        },
        payload,
      },
    ) => {
      const { comments } = state.pages[slug];
      const unionCommentItems = [...comments.items, ...payload];
      const newComments = {
        ...comments,
        items: unionCommentItems,
        isLoading: false,
        isLoaded: true,
      };

      state.pages[slug].comments = newComments;
    },
    [loadComments.rejected]: (
      state,
      {
        error,
        meta: {
          arg: { slug },
        },
      },
    ) => {
      state.pages[slug].comments.isLoading = false;
      state.errors.comments.items = error;
    },
    [sendLike.pending]: (
      state,
      {
        meta: {
          arg: { slug, commentId },
        },
      },
    ) => {
      const article = state.pages[slug];
      const commentIndex = article.comments.items.findIndex(({ id }) => id === commentId);
      const comment = article.comments.items[commentIndex];

      comment.isVoting = true;
      comment.isVoted = false;
      comment.error = '';
    },
    [sendLike.fulfilled]: (
      state,
      {
        meta: {
          arg: { slug, commentId },
        },
        payload: { karma, likeStatus },
      },
    ) => {
      const article = state.pages[slug];
      const commentIndex = article.comments.items.findIndex(({ id }) => id === commentId);
      const comment = article.comments.items[commentIndex];

      comment.isVoting = false;
      comment.isVoted = true;
      comment.karma = karma;
      comment.like = likeStatus;
    },
    [sendLike.rejected]: (
      state,
      {
        meta: {
          arg: { slug, commentId },
        },
        error,
      },
    ) => {
      const article = state.pages[slug];
      const commentIndex = article.comments.items.findIndex(({ id }) => id === commentId);
      const comment = article.comments.items[commentIndex];

      comment.isVoting = false;
      comment.isVoted = false;
      comment.error = error;
    },
    [loadPreview.pending]: state => {
      state.isPreview = true;
      state.isLoadingPreview = true;
      state.isLoadedPreview = false;
      state.isFailrulePreview = false;
    },
    [loadPreview.fulfilled]: (state, { payload }) => {
      state.isLoadingPreview = false;
      state.isLoadedPreview = true;
      state.preview = payload;
    },
    [loadPreview.rejected]: state => {
      state.isLoadingPreview = false;
      state.isFailrulePreview = true;
    },
  },
});

const { reducer, actions } = articlesSlice;

export const { clearPreview, commentsClearErrorForm } = actions;

export default reducer;
