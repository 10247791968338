/** state/promo */
import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('promo', [
  'SET_IS_BOT',
  'SET_REQUEST_DATA',

  'LOADING_START',
  'LOADING_STOP',
  'LOADING_FAILURE',

  'RESERVATION_PROMO',
  'UNRESERVATION_PROMOS',

  'INIT_PAY_2_PAY',
]);
