import { get } from 'utils/api';
import types from './types';

/**
 *
 * @param {('header_button'|'side_widget'|'bar_widget')} type
 */
export const loadButton = type => {
  return get({
    url: `/button/${type}`,
  });
};

const loadHeaderButtonStart = () => ({
  type: types.LOADING_HEADER_BUTTON_START,
});

const loadHeaderButtonStop = data => ({
  type: types.LOADING_HEADER_BUTTON_STOP,
  data: data.button,
});

const loadHeaderButtonFailure = error => ({
  type: types.LOADING_HEADER_BUTTON_FAILURE,
  error,
});

export const loadHeaderButton = () => (dispatch, getState) => {
  const {
    headerButton: { isLoaded, isLoading },
  } = getState().customization;

  if (isLoaded || isLoading) {
    return;
  }

  dispatch(loadHeaderButtonStart());

  loadButton('header_button')
    .then(data => dispatch(loadHeaderButtonStop(data.message)))
    .catch(() => dispatch(loadHeaderButtonFailure()));
};

const loadSideWidgetStart = () => ({
  type: types.LOADING_SIDE_WIDGET_START,
});

const loadSideWidgetStop = data => ({
  type: types.LOADING_SIDE_WIDGET_STOP,
  data,
});

const loadSideWidgetFailure = error => ({
  type: types.LOADING_SIDE_WIDGET_FAILURE,
  error,
});

export const loadSideWidget = () => (dispatch, getState) => {
  const {
    sideWidget: { isLoaded, isLoading },
  } = getState().customization;

  if (isLoaded || isLoading) {
    return;
  }

  dispatch(loadSideWidgetStart());

  loadButton('side_widget')
    .then(data => dispatch(loadSideWidgetStop(data.message)))
    .catch(() => dispatch(loadSideWidgetFailure()));
};

const loadBarWidgetStart = () => ({
  type: types.LOADING_BAR_WIDGET_START,
});

const loadBarWidgetStop = data => ({
  type: types.LOADING_BAR_WIDGET_STOP,
  data,
});

const loadBarWidgetFailure = error => ({
  type: types.LOADING_BAR_WIDGET_FAILURE,
  error,
});

export const loadBarWidget = () => (dispatch, getState) => {
  const {
    barWidget: { isLoaded, isLoading },
  } = getState().customization;

  if (isLoaded || isLoading) {
    return;
  }

  dispatch(loadBarWidgetStart());

  loadButton('bar_widget')
    .then(data => dispatch(loadBarWidgetStop(data.message)))
    .catch(() => dispatch(loadBarWidgetFailure()));
};
