const ARTIST = 'Artist';
const EMERGING_ARTIST = 'EmergingArtist';
const STUDIO = 'Studio';
const AUTHOR = 'Author';
const COMPANY = 'Company';
const DEFAULT = 'Default';

const Roles = {
  Artist: ARTIST,
  EmergingArtist: EMERGING_ARTIST,
  Studio: STUDIO,
  Author: AUTHOR,
  Company: COMPANY,
  Default: DEFAULT,
};

const checkAccess = (userRoles, pageRoles) =>
  (pageRoles || []).some(role => userRoles.indexOf(role) !== -1);

const getRole = userRoles =>
  userRoles.find(role => [ARTIST, COMPANY, EMERGING_ARTIST, STUDIO].includes(role)) || DEFAULT;

module.exports = {
  Roles,
  checkAccess,
  getRole,
};
