import types from './types';

const initialState = {
  data: {},
  isLoading: false,
  isLoaded: false,

  pages: {},

  error: {},
};

export default function jobBoardPartners(state = initialState, action) {
  switch (action.type) {
    /** Страница списка партнеров  */
    case types.LOAD_JOB_PARTNERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
      };
    case types.LOAD_JOB_PARTNERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        data: action.data,
      };

    default:
      return state;
  }
}
