import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('jobs', [
  'LOADING_START',
  'LOADING_STOP',
  'LOADING_FAILURE',

  'LOADING_JOBS_START',
  'LOADING_JOBS_STOP',
  'LOADING_JOBS_FAILURE',
]);
