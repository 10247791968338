import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Router } from 'utils/routes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import parse from 'url-parse';
import { createURL } from 'utils/url';

const isModifiedEvent = event => {
  return Boolean(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
};

const addFeatureParamsToUrl = (originalLink, parsedAsPath) => {
  const parsedUrl = parse(originalLink, true);
  const mergedQuery = {
    ...parsedUrl.query,
    ft: parsedAsPath.query.ft,
  };
  return createURL(originalLink, mergedQuery);
};

const AppLink = React.forwardRef(function(
  {
    className,
    children,
    href,
    as,
    onClick,
    targetBlank,
    download,
    disabled,
    external,
    params,
    ...otherProps
  },
  forwardedRef,
) {
  const prevHref = useRef(null);

  const isMetaLink = useMemo(() => /^(mailto|tel|skype):/.test(as || href), [as, href]);

  const router = useRouter();
  const url = useMemo(() => {
    let originalLink = as || href;
    const parsedAsPath = parse(router.asPath, true);
    if (parsedAsPath.query.ft) {
      originalLink = addFeatureParamsToUrl(originalLink, parsedAsPath);
    }
    if (originalLink && !isMetaLink && !/^https?:\/\//g.test(originalLink)) {
      const querySearch = originalLink.search(/(#|\?)/g);

      if (~querySearch) {
        if (originalLink[querySearch - 1] !== '/') {
          return `${originalLink.slice(0, querySearch)}/${originalLink.slice(
            querySearch,
            originalLink.length,
          )}`;
        }
      } else {
        if (originalLink[originalLink.length - 1] !== '/') {
          return `${originalLink}/`;
        }
      }
    }

    return originalLink;
  }, [as, href, isMetaLink, router.asPath]);

  // Свойства ссылки
  const componentProps = useMemo(
    () => ({
      target: targetBlank ? '_blank' : undefined,
      rel: targetBlank ? 'nofollow noopener' : undefined,
      download: download ? true : undefined,
    }),
    [download, targetBlank],
  );

  // Обработка события клика
  const onClickDispatcher = useCallback(
    e => {
      // отмена перехода по ссылке
      if (onClick(e) === false || disabled || !url || isMetaLink) {
        return;
      }

      // Отмена перехода если новая ссылка на текущий url
      if (url === prevHref.current) {
        e.preventDefault();
        return;
      }

      const isExternal = url && (external || url.indexOf('://') >= 0);

      if (!isExternal) {
        if (
          !e.defaultPrevented && // onClick prevented default
          e.button === 0 && // ignore everything but left clicks
          !targetBlank && // let browser handle "target=_blank" etc.
          !isModifiedEvent(e) // ignore clicks with modifier keys
        ) {
          e.preventDefault();
          Router.pushRoute(url, params);
        }
      }
    },
    [onClick, disabled, url, isMetaLink, external, targetBlank, params],
  );

  // Обработчик события для компонента Link
  const asLinkHandler = useCallback(
    e => {
      onClick(e);
      if (as === prevHref.current) {
        e.preventDefault();
        return;
      }
    },
    [as, onClick],
  );

  // Хак для отключения закликивания
  useEffect(() => {
    /** Слушатель события смены роута.
     * @param {string} url
     */
    const handleRouteChangeStart = url => {
      prevHref.current = url;
    };

    Router.router.events.on('routeChangeStart', handleRouteChangeStart);
    () => Router.router.events.off('routeChangeStart', handleRouteChangeStart);
  });

  return as ? (
    <Link href={href} as={url}>
      <a className={className} onClick={asLinkHandler}>
        {children}
      </a>
    </Link>
  ) : (
    <a
      {...otherProps}
      {...componentProps}
      ref={forwardedRef}
      href={!disabled ? url : undefined}
      disabled={disabled}
      className={className}
      onClick={targetBlank || download ? onClick : onClickDispatcher}
    >
      {children}
    </a>
  );
});

AppLink.displayName = 'AppLink';

AppLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  as: PropTypes.string,
  external: PropTypes.bool,
  download: PropTypes.bool,
  disabled: PropTypes.bool,
  targetBlank: PropTypes.bool,
  params: PropTypes.object,
  router: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

AppLink.defaultProps = {
  children: null,
  href: null,
  as: null,
  external: false,
  download: false,
  targetBlank: false,
  params: {},
  className: '',
  onClick: () => true,
};

export default AppLink;
