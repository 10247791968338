// Vendor
import React from 'react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import cn from 'classnames';
import AppLink from 'components/AppLink';
import { Routes80lv } from 'config/routes';
import styles from './styles.styl';

function DetailsMenu({ toggleDropdown, logout }) {
  return (
    <div className={styles.DetailsMenu}>
      <AppLink
        className={cn(styles.DetailsMenu__item)}
        href="/profile/settings"
        onClick={toggleDropdown}
      >
        <img
          src="/static/icons/profile-login.svg"
          alt="profile"
          className={styles.DetailsMenu__icon}
        />
        Profile
      </AppLink>
      <AppLink
        className={cn(styles.DetailsMenu__item)}
        href="/profile/bookmarks"
        onClick={toggleDropdown}
      >
        <img src="/static/icons/bookmark.svg" alt="bookmark" className={styles.DetailsMenu__icon} />
        Bookmarks
      </AppLink>
      <span
        className={cn(styles.DetailsMenu__item)}
        onClick={() => {
          toggleDropdown();
          logout();
          Router.replace(Routes80lv.Home.pattern, Routes80lv.Home.pattern, { shallow: true });
        }}
      >
        <img src="/static/icons/logout.svg" alt="bookmark" className={styles.DetailsMenu__icon} />
        Logout
      </span>
    </div>
  );
}

DetailsMenu.propTypes = {
  logout: PropTypes.func,
  toggleDropdown: PropTypes.func,
};

export default DetailsMenu;
