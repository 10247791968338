import types from './types';
import immutable from 'object-path-immutable';

const initialState = {
  page: 1,
  sort: 'new',
  isLoadingJobs: false,

  sortOptions: [
    { value: 'new', label: 'New' },
    { value: 'popular', label: 'Popular' },
    { value: 'discussed', label: 'Most discussed' },
  ],
  categories: {},
  errors: {},
};

export default function category(state = initialState, action) {
  switch (action.type) {
    /** Загрузка категории */
    case types.LOADING_START:
      return Object.assign({}, state, {
        page: action.page,
        sort: action.sort,
        categories: immutable.set(state.categories, action.category, {
          isLoading: true,
          isLoaded: false,
        }),
      });
    // break;

    case types.LOADING_STOP:
      return Object.assign({}, state, {
        categories: immutable.set(state.categories, action.category, {
          ...action.data,
          isLoading: false,
          isLoaded: true,
        }),
      });
    // break;

    case types.LOADING_FAILURE:
      return Object.assign({}, state, {
        categories: immutable.set(state.categories, action.category, {
          isLoading: false,
          isLoaded: false,
        }),
        errors: Object.assign({}, state.errors, {
          [action.category]: action.error,
        }),
      });
    // break;

    /** Подгрузка вакансий */
    case types.LOADING_JOBS_START:
      return Object.assign({}, state, {
        isLoadingJobs: true,
      });
    // break;

    case types.LOADING_JOBS_STOP:
      return Object.assign({}, state, {
        page: action.page,
        sort: action.sort,
        categories: immutable.update(state.categories, [action.category, 'jobs'], jobs =>
          Object.assign({}, jobs, action.data),
        ),
        isLoadingJobs: false,
      });
    // break;

    case types.LOADING_JOBS_FAILURE:
      return Object.assign({}, state, {
        isLoadingJobs: false,
      });
    // break;

    default:
      return state;
  }
}
