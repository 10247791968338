import defineActionTypes from 'utils/defineActionTypes';

// Define action types
export default defineActionTypes('bookmarks', [
  'LOAD_BOOKMARKS_REQUEST',
  'LOAD_BOOKMARKS_SUCCESS',
  'UPDATE_BOOKMARKS_REQUEST',
  'UPDATE_BOOKMARKS_SUCCESS',
  'UPDATE_BOOKMARKS_FAILURE',
]);
