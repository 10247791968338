// CustomPromoContent
import React, { useRef, useCallback, useEffect } from 'react';
import cn from 'classnames/bind';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { initPay2Pay } from 'state/promo/actions';

// Static
import xsollaConfig from 'config/xsolla.json';

// Components
// import AppLink from 'components/AppLink'; // TODO: #1

// Styles
import styles from './styles.styl';

const cx = cn.bind(styles);

const PAY_2_PAY_ID = 'pay2play';

const CustomPromoContent = ({
  leftSide,
  title,
  title_color,
  by,
  by_color,
  by_prefix,
  by_prefix_color,
  description,
  description_color,
  promo_bg_color,
  promo_bg_image,
  widget_options,
  url, // TODO: На какой элемен вешается ссылка? #1
  group,
  inArticle,
  onClick,
  className,
}) => {
  const dispatch = useDispatch();
  const widgetRef = useRef();
  const isInitPay2Pay = useSelector(state => state.promo.isInitPay2Pay);

  const initWidget = useCallback(() => dispatch(initPay2Pay()), [dispatch]);

  // Загрузка скрипта pay2pay
  useEffect(() => {
    if (!document.getElementById(PAY_2_PAY_ID)) {
      const s = document.createElement('script');

      s.id = PAY_2_PAY_ID;
      s.src = xsollaConfig.pay2play;
      s.type = 'text/javascript';
      s.async = true;
      s.addEventListener('load', () => initWidget());

      document.getElementsByTagName('head')[0].appendChild(s);
    }
  }, [initWidget]);

  // Добавление виджета
  useEffect(() => {
    if (widgetRef.current && isInitPay2Pay && widget_options) {
      XPay2PlayWidget.create({
        ...widget_options,
        target_element: widgetRef.current,
        lightbox: {
          height: '60%',
        },
      });
    }
  }, [isInitPay2Pay, widget_options]);

  return (
    <div
      className={cx(
        'CustomPromoContent',
        { CustomPromoContent_leftSide: leftSide, CustomPromoContent_group: group },
        className,
      )}
      style={{
        backgroundImage: promo_bg_image ? `url(${promo_bg_image})` : null,
      }}
    >
      {promo_bg_color && (
        <div
          className={cx('CustomPromoContent__colorLayer')}
          style={{
            backgroundColor: promo_bg_color,
          }}
        />
      )}

      <figure
        className={cx('CustomPromoContent__info')}
        onClick={e => {
          onClick(e);
          window.open(url);
        }}
      >
        {!leftSide && (
          <figcaption className={cx('CustomPromoContent__title')} style={{ color: title_color }}>
            {title}
          </figcaption>
        )}
        <blockquote>
          {!leftSide && (
            <div className={cx('CustomPromoContent__quote')}>
              <span stype={{ color: by_prefix_color }}>{`${by_prefix || 'by'} `}</span>
              <span className={cx('CustomPromoContent__author')} style={{ color: by_color }}>
                {by}
              </span>
            </div>
          )}
          <p className={cx('CustomPromoContent__description')} style={{ color: description_color }}>
            {description}
          </p>
        </blockquote>
      </figure>

      <div
        ref={widgetRef}
        className={cx('CustomPromoContent__widget', {
          CustomPromoContent__widget_inArticle: inArticle,
        })}
      ></div>
    </div>
  );
};

CustomPromoContent.propTypes = {
  leftSide: PropTypes.bool,
  group: PropTypes.bool,
  title: PropTypes.string,
  title_color: PropTypes.string,
  by: PropTypes.string,
  by_color: PropTypes.string,
  by_prefix: PropTypes.string,
  by_prefix_color: PropTypes.string,
  description: PropTypes.string,
  description_color: PropTypes.string,
  promo_bg_color: PropTypes.string,
  promo_bg_image: PropTypes.string,
  url: PropTypes.string,
  widget_options: PropTypes.object,
  inArticle: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

CustomPromoContent.defaultProps = {
  leftSide: false,
  group: false,
  title: '',
  title_color: '',
  by: '',
  by_color: '',
  by_prefix: '',
  by_prefix_color: '',
  description: '',
  description_color: '',
  promo_bg_color: '',
  promo_bg_image: '',
  url: '',
  widget_options: {},

  className: '',
};

export default CustomPromoContent;
