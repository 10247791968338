import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import config from 'config/google.json';

// Components
import Container from 'components/Container';
import Portal from 'components/Portal';

import styles from './styles.styl';

function ModalSearch({ closeModal }) {
  const modal = useRef(null);
  const content = useRef(null);

  useEffect(() => {
    // функция закрытия модалки по escape
    function pressOnEsc(e) {
      if (e.keyCode === 27) {
        closeModal();
      }
    }

    document.addEventListener('keyup', pressOnEsc, false);

    return () => {
      document.removeEventListener('keyup', pressOnEsc, false);
    };
  }, [closeModal]);

  useEffect(() => {
    (function() {
      var id = 'google-search-script';
      var cx = config.CX;
      var gcse;

      if (!document.getElementById(id)) {
        const myInitCallback = function() {
          if (document.readyState == 'complete') {
            // Document is ready when CSE element is initialized.
            // Render an element with both search box and search results in div with id 'test'.
            google.search.cse.element.render({
              div: 'google-searchbox',
              tag: 'search',
            });
            document.querySelector('input[class="gsc-input"][name="search"]').focus();
          } else {
            // Document is not ready yet, when CSE element is initialized.
            google.setOnLoadCallback(function() {
              // Render an element with both search box and search results in div with id 'test'.
              google.search.cse.element.render({
                div: 'google-searchbox',
                tag: 'search',
              });
              document.querySelector('input[class="gsc-input"][name="search"]').focus();
            }, true);
          }
        };

        // Insert it before the CSE code snippet so the global properties like parsetags and callback
        // are available when cse.js runs.
        window.__gcse = {
          parsetags: 'explicit',
          initializationCallback: myInitCallback,
        };

        gcse = document.createElement('script');
        gcse.id = id;
        gcse.type = 'text/javascript';
        gcse.async = true;
        gcse.src = `${
          document.location.protocol == 'https:' ? 'https:' : 'http:'
        }//www.google.com/cse/cse.js?cx=${cx}`;
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(gcse, s);
      } else {
        if (google) {
          google.search.cse.element.render({
            div: 'google-searchbox',
            tag: 'search',
          });
          document.querySelector('input[class="gsc-input"][name="search"]').focus();
        }
      }
    })();
  }, []);

  return (
    <div ref={modal} className={cn(styles.ModalSearch)}>
      <Portal selector="#closeButton">
        <button className={styles.ModalSearch__close} onClick={closeModal}>
          <img
            src="/static/icons/close-search.svg"
            alt="Close"
            className={styles.ModalSearch__closeIcon}
          />
        </button>
      </Portal>
      <div className={styles.ModalSearch__inner}>
        <Container>
          <div ref={content} className={styles.ModalSearch__content}>
            <div className={styles.ModalSearch__body}>
              <div className={styles.ModalSearch__header}>
                <div className={styles.ModalSearch__field}>
                  <div id="google-searchbox" className="gcse-search"></div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

ModalSearch.propTypes = {
  closeModal: PropTypes.func,
};

export default ModalSearch;
