// Vendor
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.styl';

const Heading = React.forwardRef(
  ({ className, children, level, tagName, style, ...otherProps }, ref) => {
    const TagName = tagName || `h${level}`;

    return (
      <TagName
        ref={ref}
        style={style}
        {...otherProps}
        className={cn(styles.Heading, className, {
          [styles[`Heading_level_${level}`]]: level,
        })}
      >
        {children}
      </TagName>
    );
  },
);

Heading.displayName = 'Heading';

Heading.defaultProps = {
  className: '',
  level: 2,
  tagName: '',
  style: null,
};

Heading.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  level: PropTypes.number,
  tagName: PropTypes.any,
  style: PropTypes.object,
};

export default Heading;
