import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import useAuth from '../../hooks/useAuth';
import { getToken, removeToken, isTokenExpires } from 'features/auth/utils/auth';

import { clearComment, clearArticleSlug } from 'utils/comments';

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const { token, isAuthenticated, login, logout } = useAuth();

  useEffect(() => {
    const cookieToken = getToken();
    if (cookieToken) {
      if (isTokenExpires(cookieToken)) {
        removeToken();
        logout();
      } else {
        login(cookieToken);
      }
    } else {
      logout();
    }
  }, [dispatch, login, logout]);

  useEffect(() => {
    if (token) {
      if (!isAuthenticated) {
        clearArticleSlug();
        clearComment();
        removeToken();
      }
    }
  }, [isAuthenticated, token]);

  return children;
};

export default Auth;
