import { removeToken } from '../../utils/auth';

import types from './types';

const initialState = {
  isAuthenticated: false,
  isLoaded: false,
  roles: [],
  token: '',
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_AUTH: {
      return {
        ...state,
        isAuthenticated: true,
        isLoaded: true,
        roles: action.roles,
        token: action.token,
      };
    }

    case types.SET_ROLES: {
      return {
        ...state,
        roles: action.roles,
      };
    }

    case types.REMOVE_AUTH: {
      removeToken();
      return {
        ...state,
        isAuthenticated: false,
        isLoaded: true,
        roles: [],
        token: '',
      };
    }

    default:
      return state;
  }
}
