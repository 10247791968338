/** latesArticles
 * В снипете представлены методы для обработки
 * данных пагинации - lates-articles
 */

export const ARTICLES_TYPE = 'articles';
export const ARTICLES_TOTAL = 9;

/** Добавление дополнительный данных ко всем статьям в списке.
 * @param {array} articles
 * @param {string} pageData
 * @returns {object}
 */
export function setArticleData(articles = [], pageData) {
  return articles.map(article => Object.assign({}, article, { page: pageData }));
}

/** Обновление данных при загрузке страницы
 * @param {object} data - данные с бека
 * @param {object} sort - значение сортировки
 * @param {object} page - номер страницы
 * @returns {object}
 */
export function initialSnippet(data, sort, page) {
  return Object.assign({}, data, {
    loadedPages: { [sort]: [page] },
    [ARTICLES_TYPE]: Object.assign({}, data[ARTICLES_TYPE], {
      totals: { [sort]: Number(data[ARTICLES_TYPE] ? data[ARTICLES_TYPE].total : 0) },
      items: { [sort]: data[ARTICLES_TYPE] ? setArticleData(data[ARTICLES_TYPE].items, page) : [] },
    }),
  });
}

/** Сниппет для подгруженных данных при смене пагинации
 * или метода сортировки
 *
 * @param {string} slug
 * @param {string} data - данные с бека
 * @param {object} sort - значение сортировки
 * @param {object} page - номер страницы
 * @param {string} state - state страницы
 * @returns {object}
 */
export function loadingSnippet(slug = 'all', data, sort, page, state) {
  const latesArticles = state[slug][ARTICLES_TYPE] || {
    totals: {},
    items: {},
  };
  const articles = setArticleData(data.items, page);

  return {
    totals: Object.assign({}, latesArticles.totals, {
      [sort]: Number(data.total),
    }),
    items: Object.assign({}, latesArticles.items, {
      [sort]: !latesArticles.items[sort] ? articles : latesArticles.items[sort].concat(articles),
    }),
  };
}

/** Сниппет для подготовки данных подгрузки.
 * Проверяет, загружена ли страница и считает сколлько статей
 * нужно подгрузить.
 *
 * @param {object} entry - данные страницы с пагинацией
 * @param {object} sort - значение сортировки
 * @param {object} page - номер страницы
 * @returns {object}
 */
export function prepareLoadingSnippet(entry = {}, sort, page) {
  const loadedPages = entry.loadedPages || {};
  const isExistPage = loadedPages[sort] && loadedPages[sort].includes(page);

  return { isExistPage };
}

/** Сниппет для главной страницы
 * На главной нет сортировки
 *
 * @param {object} data - данные с бека
 * @param {object} page - номер страницы
 * @returns {object}
 */
export function homeInitialSnippet(data, page) {
  return Object.assign({}, data, {
    loadedPages: [page],
    [ARTICLES_TYPE]: Object.assign({}, data[ARTICLES_TYPE], {
      total: data[ARTICLES_TYPE] ? Number(data[ARTICLES_TYPE].total) : 0,
      items: data[ARTICLES_TYPE] ? setArticleData(data[ARTICLES_TYPE].items, page) : [],
    }),
  });
}

/** Сниппет для подгрузки статей на главной
 * @param {number} page
 * @param {object} data
 * @returns {object}
 */
export function homeLoadingSnippet(page, data) {
  return {
    total: data.total,
    items: setArticleData(data.items, page),
  };
}
