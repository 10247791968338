import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './styles.styl';

const Paragraph = props => {
  const { weightType, className, font, children, level, ...otherProps } = props;

  const elClassname = cls(
    styles.Paragraph,
    styles[`Paragraph__font${font}`],
    styles[`Paragraph__${weightType}${level}`],
    className,
  );

  return React.createElement('p', { ...otherProps, className: elClassname }, children);
};

Paragraph.defaultProps = {
  weightType: 'regular',
};

Paragraph.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  font: PropTypes.oneOf(['EuclidCircularB', 'NoticiaText']).isRequired,
  level: PropTypes.string.isRequired,
  weightType: PropTypes.oneOf(['regular']),
  className: PropTypes.string,
};

export { Paragraph };
