import { useRef, useEffect } from 'react';

export default function useWasUpdated() {
  const isUpdate = useRef(false);

  useEffect(() => {
    isUpdate.current = true;
  });

  return isUpdate.current;
}
