// Vendor
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import AppLink from 'components/AppLink';
import MovingArrow from 'components/MovingArrow';
import { Typography } from 'ui/Typography';

import styles from './styles.styl';

class Button extends PureComponent {
  static defaultProps = {
    className: '',
    contentClassName: '',
    arrowClassName: '',
    type: 'button',
    href: null,
    disabled: false,
    isLoading: false,
    withArrow: false,
    withIcon: false,
    back: false,
    small: false,
    theme: 'default',
    forwardRef: null,
    onClick: () => {},
  };

  static propTypes = {
    preset: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
    size: PropTypes.oneOf(['large', 'medium', 'small']),
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    arrowClassName: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.node,
    ]).isRequired,
    type: PropTypes.string,
    href: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    withArrow: PropTypes.bool,
    withIcon: PropTypes.bool,
    isLink: PropTypes.bool,
    back: PropTypes.bool,
    small: PropTypes.bool,
    theme: PropTypes.oneOf([
      'default',
      'withShadow',
      'link',
      'none',
      'text',
      'dark',
      'dark-transparent',
    ]),
    forwardRef: PropTypes.object,
    onClick: PropTypes.func,
  };

  renderChildren = contentClassName => {
    return (
      <span className={cn(styles.Button__content, contentClassName)}>{this.props.children}</span>
    );
  };

  renderPreset = (size, contentClassName) => (
    <Typography.Label
      weightType="medium"
      level={size === 'small' ? '4' : '3'}
      className={cn(styles.Button__typography, contentClassName)}
    >
      {this.props.children}
    </Typography.Label>
  );

  render() {
    const {
      preset,
      size,
      className,
      contentClassName,
      arrowClassName,
      isLoading,
      disabled,
      withArrow,
      withIcon,
      back,
      small,
      type,
      href,
      theme,
      isLink,
      forwardRef,
      ...otherProps
    } = this.props;

    const buttonClassNames = cn(styles.Button, className, {
      [styles[`Button_${theme}`]]: Boolean(theme),
      [styles.Button_withArrow]: withArrow,
      [styles.Button_back]: back,
      [styles.Button_small]: small,
      [styles.Button_withIcon]: withIcon,
      [styles[`Button_${preset}Preset`]]: Boolean(preset),
      [styles[`Button_${size}Size`]]: Boolean(size),
      [styles.Button_disabled]: disabled,
    });

    if (preset) {
      if (withArrow && (isLink || href)) {
        return (
          <MovingArrow.Wrapper>
            <AppLink ref={forwardRef} {...otherProps} href={href} className={buttonClassNames}>
              {this.renderPreset(size)}
              <MovingArrow className={cn(styles.Button__arrow, arrowClassName)} />
            </AppLink>
          </MovingArrow.Wrapper>
        );
      }

      if (withArrow) {
        return (
          <MovingArrow.Wrapper>
            <button
              {...otherProps}
              ref={forwardRef}
              type={type}
              className={buttonClassNames}
              disabled={disabled}
            >
              {this.renderPreset(size)}
              <MovingArrow className={styles.Button__arrow} />
            </button>
          </MovingArrow.Wrapper>
        );
      }

      if (isLink || href) {
        return (
          <AppLink ref={forwardRef} {...otherProps} href={href} className={buttonClassNames}>
            {this.renderPreset(size)}
          </AppLink>
        );
      }

      return (
        <button
          {...otherProps}
          ref={forwardRef}
          type={type}
          className={buttonClassNames}
          disabled={disabled}
        >
          {this.renderPreset(size)}
        </button>
      );
    }

    if (withArrow && (isLink || href)) {
      return (
        <MovingArrow.Wrapper>
          <AppLink ref={forwardRef} {...otherProps} href={href} className={buttonClassNames}>
            {this.renderChildren(contentClassName)}
            <MovingArrow className={cn(styles.Button__arrow, arrowClassName)} />
          </AppLink>
        </MovingArrow.Wrapper>
      );
    }

    if (withArrow && preset) {
      <MovingArrow.Wrapper>
        <button
          {...otherProps}
          ref={forwardRef}
          type={type}
          className={buttonClassNames}
          disabled={disabled}
        >
          {this.renderPreset(size)}
          <MovingArrow className={styles.Button__arrow} />
        </button>
      </MovingArrow.Wrapper>;
    }

    if (withArrow && !isLink && !href) {
      return (
        <MovingArrow.Wrapper>
          <button
            {...otherProps}
            ref={forwardRef}
            type={type}
            className={buttonClassNames}
            disabled={disabled}
          >
            {this.renderChildren(contentClassName)}
            <MovingArrow className={styles.Button__arrow} />
          </button>
        </MovingArrow.Wrapper>
      );
    }

    if (isLink) {
      return (
        <AppLink ref={forwardRef} {...otherProps} href={href} className={buttonClassNames}>
          {this.renderChildren()}
        </AppLink>
      );
    }

    return (
      <button
        {...otherProps}
        ref={forwardRef}
        type={type}
        className={buttonClassNames}
        disabled={disabled || isLoading}
      >
        {this.renderChildren()}
        {isLoading && <span>Loading</span>}
      </button>
    );
  }
}

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, forwardRef) => (
  <Button forwardRef={forwardRef} {...props} />
));
