import React from 'react';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.styl';

const Tooltip = ({ className, ...props }) => (
  <ReactTooltip className={cn(styles.Tooltip, className)} {...props} />
);

Tooltip.propTypes = {
  className: PropTypes.string,
};

export default Tooltip;
