import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.styl';

const BlockLabel = ({ children, className, ...other }) => {
  return (
    <div className={cn(styles.BlockLabel, className)} {...other}>
      {children}
    </div>
  );
};

BlockLabel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default BlockLabel;
