import types from './types';
import { lockScroll, unlockScroll } from 'utils/scrollLock.js';

export const actions = {
  openModal: modalParams => {
    const options = modalParams.lockOptions || {};
    lockScroll(options);
    return {
      type: types.SHOW_MODAL,
      ...modalParams,
    };
  },
  closeModal: () => {
    unlockScroll();
    return { type: types.HIDE_MODAL };
  },
};
