import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Components
import AppLink from 'components/AppLink';
import Button from 'components/Button';
import ToggleButton from './ToggleButton';
import ArrowIcon from 'ui/icons/ArrowIcon';

import styles from './styles.styl';

const Pagination = ({
  className,
  setHref,
  length,
  currentNumber,
  disabled,
  prevButtonText,
  nextButtonText,
  showPrevButtonOnFirstPage,
  onClick,
}) => {
  const eventHandling = useCallback(
    (e, val) => {
      e.preventDefault();

      if (onClick && !disabled) {
        onClick(val);
      }
    },
    [disabled, onClick],
  );

  const handleClick = useCallback(
    e => {
      eventHandling(e, Number(e.currentTarget.innerHTML));

      return false;
    },
    [eventHandling],
  );

  const onPrev = useCallback(
    e => {
      Boolean(currentNumber - 1) && eventHandling(e, currentNumber - 1);

      return false;
    },
    [currentNumber, eventHandling],
  );

  const onNext = useCallback(
    e => {
      currentNumber + 1 <= length && eventHandling(e, currentNumber + 1);

      return false;
    },
    [currentNumber, eventHandling, length],
  );

  // массив кнопок пагинации
  const buttons = useMemo(() => {
    const buttons = [];

    let i = length <= 5 ? 1 : Math.max(currentNumber - 2, 1);
    const lengthVal = length <= 5 ? length : Math.min(i + 4, length);

    for (; i <= lengthVal; i++) {
      buttons.push(
        <li
          key={i}
          className={cn(styles.Pagination__item, {
            [styles.Pagination__item_active]: i === Number(currentNumber),
          })}
        >
          <AppLink
            href={setHref(i)}
            tabIndex="0"
            className={styles.Pagination__link}
            onClick={handleClick}
          >
            {i}
          </AppLink>
        </li>,
      );
    }

    return buttons;
  }, [currentNumber, handleClick, length, setHref]);

  return length <= 1 ? null : (
    <ul
      className={cn(
        styles.Pagination,
        {
          [styles.Pagination_withButtons]: length > 5,
          [styles.Pagination_disabled]: disabled,
        },
        className,
      )}
    >
      <ToggleButton in={currentNumber > 1 || showPrevButtonOnFirstPage}>
        <li className={cn(styles.Pagination__item, styles.Pagination__item_prev)}>
          <Button
            href={currentNumber === 1 ? null : setHref(currentNumber - 1)}
            className={cn(styles.Pagination__button, styles.Pagination__link)}
            theme="none"
            preset="tertiary"
            back
            disabled={currentNumber === 1}
            onClick={onPrev}
          >
            <ArrowIcon className={styles.Pagination__icon} />
            {prevButtonText}
          </Button>
        </li>
      </ToggleButton>

      {buttons}

      {currentNumber !== length && (
        <li className={cn(styles.Pagination__item, styles.Pagination__item_next)}>
          <Button
            href={setHref(currentNumber + 1)}
            className={cn(styles.Pagination__button, styles.Pagination__link)}
            theme="none"
            preset="tertiary"
            onClick={onNext}
          >
            <ArrowIcon className={styles.Pagination__icon} />
            {nextButtonText}
          </Button>
        </li>
      )}
    </ul>
  );
};

Pagination.defaultProps = {
  className: '',
  currentNumber: 1,
  setHref: () => undefined,
  disabled: false,
  onClick: null,
  showPrevButtonOnFirstPage: false,
};

Pagination.propTypes = {
  className: PropTypes.string,
  setHref: PropTypes.func,
  length: PropTypes.number.isRequired,
  currentNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  showPrevButtonOnFirstPage: PropTypes.boolean,
  prevButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
  onClick: PropTypes.func,
};

export default Pagination;
