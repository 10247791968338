import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updatePromos, initPromos } from 'state/promo/actions';

/**
 * Хук на получение баннеров.
 * Данный хук, на основе вхожных данных формирует запрос на получение баннеров
 * и сохраняет их в srore.promo.promos;
 * Поскольку запрос на получение баннеров не контролируется
 * флагами isLoaded/isLoading все параметры переданные в initPromos
 * должны быть меморизированными и иметь как можно меньше изменений.
 *
 * @param {object} promo - объект с id всех баннеров по позициям
 * @param {object[]} content - контент страницы
 * @param {object[]} requred - типы и количество обязательных баннеров
 * @param {string[]} categories - список категорий
 * @param {string[]} vendors - список вендоров
 * @returns {function} - функция обновления баннеров на ТЕКУЩЕЙ странице
 */
export default function usePromos(promo = {}, content, requred, categories, vendors) {
  const dispatch = useDispatch();
  const update = useCallback(() => dispatch(updatePromos()), [dispatch]);

  // Получение баннеров
  useEffect(() => {
    dispatch(initPromos(promo, content || [], requred, categories || [], vendors || []));
  }, [categories, content, dispatch, promo, requred, vendors]);

  return update;
}
