import { logout } from 'features/auth/state/auth/actions';

const defaultError = {
  name: 'Request failed',
  message: 'Oops! Something went wrong. Try again later.',
  code: 0,
  status: 0,
};

const configureUtilService = store => ({
  getToken: () => (store.getState().auth.token ? { token: store.getState().auth.token } : {}),
  checkAuthError: err => {
    if (err.response && err.response.data && err.response.data.status === 401) {
      store.dispatch(logout());
    }
  },
  rejectError: err => {
    return (err.response && err.response.data) || defaultError;
  },
});

export default configureUtilService;
