import types from './types';
import { getTokenData, getUserRolesFromTokenGroups } from 'features/auth/utils/auth';

const setAuth = ({ roles, token }) => ({
  type: types.SET_AUTH,
  roles,
  token,
});

export const logout = () => ({
  type: types.REMOVE_AUTH,
});

export const login = token => dispatch => {
  const { groups } = getTokenData(token);
  const roles = getUserRolesFromTokenGroups(groups);
  dispatch(setAuth({ roles, token }));
};
