import React, { Component } from 'react';
import PropTypes from 'prop-types';
import animejs from 'animejs';

import Transition from 'react-transition-group/Transition';

const DUR = 300;

class Fade extends Component {
  onEnter = node => {
    const { animationHeight } = this.props;

    const duration = this.props.duration || DUR;

    const params = animationHeight
      ? {
          height: [0, node.scrollHeight],
          easing: 'easeOutQuad',
          begin: () => {
            animejs({
              targets: node,
              duration: duration,
              opacity: [0, 1],
              easing: 'linear',
              delay: duration / 2,
            });
          },
        }
      : { opacity: [0, 1], easing: 'linear' };

    animejs.remove(node);
    animejs.set(node, { opacity: 0 });

    animejs({
      targets: node,
      duration: duration,
      ...params,
    });
  };
  onExit = node => {
    const { animationHeight } = this.props;
    const duration = this.props.duration || DUR;

    animejs.remove(node);
    const params = animationHeight
      ? {
          opacity: 0,
          begin: () => {
            animejs({
              targets: node,
              duration: duration / 2,
              height: 0,
              easing: 'easeOutQuad',
              delay: duration / 2,
            });
          },
        }
      : { opacity: 0, easing: 'linear' };

    animejs({ targets: node, duration: duration, easing: 'linear', ...params });
  };
  onEntered = node => {
    node.setAttribute('style', '');
    animejs.remove(node);
  };
  render() {
    const { in: inProp, duration, children } = this.props;

    return (
      <Transition
        in={inProp}
        timeout={duration || DUR}
        onEnter={this.onEnter}
        onExit={this.onExit}
        onEntered={this.onEntered}
        unmountOnExit
        mountOnEnter
      >
        {children}
      </Transition>
    );
  }
}

Fade.propTypes = {
  in: PropTypes.bool,
  duration: PropTypes.number,
  animationHeight: PropTypes.bool,
  children: PropTypes.any,
};

export default Fade;
