import { useCallback } from 'react';

import useAnalytics from './useAnalytics';
import usePromoViewport from './usePromoViewport';

const useAnalyticsBanner = ({ banner_id, banner_type }) => {
  const { elementClick, customEvent } = useAnalytics();

  const handleClick = useCallback(
    () => elementClick('80lv_site_banner_click', undefined, { banner_id, banner_type }),
    [banner_id, banner_type, elementClick],
  );

  const onViewport = useCallback(
    () => customEvent('80lv_site_banner_impression', { banner_id, banner_type }),
    [banner_id, banner_type, customEvent],
  );

  const ref = usePromoViewport(banner_id, onViewport);

  return { handleClick, ref };
};

export default useAnalyticsBanner;
