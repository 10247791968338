import React from 'react';
import PropTypes from 'prop-types';
import BaseModal from 'features/rfp/ui/BaseModal';
import { Link } from 'utils/routes';
import { Routes80lv } from 'config/routes';

import styles from './styles.styl';

const WidgetModal = React.forwardRef(({ closeModal, ...restProps }, ref) => {
  return (
    <BaseModal
      className={styles.WidgetModal}
      closeModal={closeModal}
      contentClassName={styles.WidgetModal__content}
      content={
        <div ref={ref}>
          <div id="xl_widget" style={{ maxWidth: '400px', height: '630px' }} />

          <p
            className={styles.WidgetModal__agreement_text}
            id="agreement"
            style={{ display: 'none' }}
          >
            By signing up and using our site, you agree to our <br />{' '}
            <Link route={Routes80lv.TermsOfUse.name}>
              <a className={styles.WidgetModal__agreement_link} target="_blank">
                Terms of Use
              </a>
            </Link>{' '}
            and our{' '}
            <Link route={Routes80lv.PrivacyPolicy.name}>
              <a className={styles.WidgetModal__agreement_link} target="_blank">
                Privacy Policy
              </a>
            </Link>
          </p>
        </div>
      }
      {...restProps}
    />
  );
});

WidgetModal.displayName = 'WidgetModal';
WidgetModal.propTypes = {
  closeModal: PropTypes.func,
};

export default WidgetModal;
