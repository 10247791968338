import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Paragraph, Label, Heading } from './presets';
import styles from './styles.styl';

const Typography = props => {
  const { element, children, className, ...otherProps } = props;

  return React.createElement(
    element,
    { className: cn(styles.Typography, className), ...otherProps },
    children,
  );
};

Typography.defaultProps = {
  element: 'p',
};

Typography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  element: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Typography.Heading = Heading;
Typography.Label = Label;
Typography.Paragraph = Paragraph;

export { Typography };
