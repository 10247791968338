import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import LoginContext from '../context/LoginContext';
import getConfig from 'next/config';

import useModal from 'hooks/useModal';
import WidgetModal from './WidgetModal';

const WIDGET_ID = 'xl_widget';
const AGREEMENT_ID = 'agreement';

const LoginProvider = ({ children }) => {
  const widgetRef = useRef();

  const createPayload = ({ redirectUrl, regSource }) => {
    return JSON.stringify({
      registration_source: regSource,
      redirectUrl: encodeURIComponent(redirectUrl),
    });
  };

  const mountWidget = useCallback(
    (id, { denyClose = false, payload, page, theme } = {}) => {
      const widgetNode = document.getElementById(WIDGET_ID);
      const mounted = widgetNode && widgetNode.firstChild;
      if (mounted) {
        return;
      }

      const redirectUrl = location.href;
      const isRFP = location.pathname.match(/\/talent/i);
      const regSource = isRFP ? 'rfp' : '80lv';

      const defaultPayload = createPayload({ redirectUrl, regSource });

      const { publicRuntimeConfig } = getConfig();
      const { Widget } = require('@xsolla/login-sdk');

      const XL = new Widget({
        projectId: publicRuntimeConfig.LOGIN_PROJECT,
        preferredLocale: 'en_US',
        callbackUrl: `${process.env.API_URL}/user/auth`,
        popupBackgroundColor: 'rgba(0,0,0,0.8)',
        payload: payload || defaultPayload,
        url: 'https://login-widget.xsolla.com/latest',
      });

      widgetRef.current = XL;

      XL.mount(id || WIDGET_ID);

      const defaultTheme = {
        background: {
          color: '#ffffff',
        },
        rounding: {
          widget: '8px',
        },
      };

      XL.on(XL.events.Open, () => {
        try {
          XL.setPage(page || 'logIn');
          XL.setTheme(theme || defaultTheme);

          const agreementNode = document.getElementById(AGREEMENT_ID);
          if (!isRFP && agreementNode) {
            agreementNode.style.display = 'block';
          }
        } catch (e) {}
      });

      XL.on(XL.events.Close, () => {
        if (denyClose) {
          return;
        }

        closeModal();
        if (mounted) {
          widgetNode.firstChild.remove();
        }

        const agreementNode = document.getElementById(AGREEMENT_ID);
        if (agreementNode) {
          agreementNode.style.display = 'none';
        }
      });
    },
    [closeModal],
  );

  const { openModal, closeModal, isOpen, Modal, ref } = useModal();

  const showFullscreenWidget = useCallback(
    config => {
      mountWidget(null, config);
      openModal();
    },
    [mountWidget, openModal],
  );

  const contextData = { mountWidget, showFullscreenWidget, createPayload };

  return (
    <LoginContext.Provider value={contextData}>
      <Modal>
        <WidgetModal
          closeModal={() => {
            closeModal();
            widgetRef.current.unmount();
          }}
          ref={ref}
          style={{ display: isOpen ? 'flex' : 'none' }}
        />
      </Modal>
      {children}
    </LoginContext.Provider>
  );
};

LoginProvider.propTypes = {
  children: PropTypes.node,
};

export default LoginProvider;
