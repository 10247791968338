import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import styles from './styles.styl';

const Label = props => {
  const { weightType, font, className, level, children, ...otherProps } = props;

  const labelFont = weightType === 'regular' ? font : `${font}Medium`;

  const elClassname = cls(
    styles.Label,
    styles[`Label__font${labelFont}`],
    styles[`Label__${weightType}${level}`],
    className,
  );

  return React.createElement(
    'label',
    {
      ...otherProps,
      className: elClassname,
    },
    children,
  );
};

Label.defaultProps = {
  weightType: 'regular',
  font: 'EuclidCircularB',
};

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  level: PropTypes.string.isRequired,
  className: PropTypes.string,
  font: PropTypes.oneOf(['EuclidCircularB']),
  weightType: PropTypes.oneOf(['medium', 'regular']),
};

export { Label };
