import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import AppLink from 'components/AppLink';

import styles from './styles.styl';

const cx = cn.bind(styles);

function CopyClipboard({ className, link, caption }) {
  const inputRef = useRef(null);

  const onClick = useCallback(e => {
    e.preventDefault();

    if (inputRef.current) {
      /* Select the text field */
      inputRef.current.select();
      inputRef.current.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand('copy');

      /* Alert the copied text */
      window.alert(`Copied the text: ${inputRef.current.value}`);
    }
  }, []);

  return (
    <AppLink href={link} className={cx('CopyClipboard', className)} onClick={onClick}>
      <input
        ref={inputRef}
        type="text"
        className={cx('CopyClipboard__input')}
        defaultValue={link}
      />
      {caption}
    </AppLink>
  );
}

CopyClipboard.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string,
  caption: PropTypes.string,
};

export default CopyClipboard;
