import types from './types';
import { ARTICLES_TYPE } from 'snippets/latesArticles';
import immutable from 'object-path-immutable';

const initialState = {
  page: 1,
  sort: 'new',
  isLoadingArticles: false,
  sortOptions: [
    { value: 'new', label: 'New' },
    { value: 'popular', label: 'Popular' },
    { value: 'discussed', label: 'Most discussed' },
  ],

  categories: {},
  errors: {},
};

export default function category(state = initialState, action) {
  switch (action.type) {
    /** Загрузка категории */
    case types.LOADING_CATEGORY_START:
      return Object.assign({}, state, {
        page: action.page,
        sort: action.sort,
        categories: immutable.set(state.categories, action.category, {
          isLoading: true,
          isLoaded: false,
        }),
      });
    // break;

    case types.LOADING_CATEGORY_STOP:
      return Object.assign({}, state, {
        categories: immutable.set(state.categories, action.category, {
          ...action.data,
          isLoading: false,
          isLoaded: true,
        }),
      });
    // break;

    case types.LOADING_CATEGORY_FAILURE:
      return Object.assign({}, state, {
        categories: immutable.set(state.categories, action.category, {
          isLoading: false,
          isLoaded: false,
        }),
        errors: Object.assign({}, state.errors, {
          [action.category]: action.error,
        }),
      });
    // break;

    /** Смена номера страницы */
    case types.CHANGE_PAGE:
      return Object.assign({}, state, {
        page: action.page,
        sort: action.sort,
      });
    // break;

    /** Добавляет номер загруженной страницы */
    case types.PUSH_LOADED_PAGE:
      return immutable.push(
        state,
        ['categories', action.category, 'loadedPages', action.sort],
        action.page,
      );
    // break;

    /** Подгрузка статей */
    case types.LOADING_ARTICLES_START:
      return Object.assign({}, state, {
        isLoadingArticles: true,
      });
    // break;

    case types.LOADING_ARTICLES_STOP:
      return Object.assign({}, state, {
        page: action.page,
        sort: action.sort,
        categories: immutable.assign(
          state.categories,
          [action.category, ARTICLES_TYPE],
          action.latesArticle,
        ),
        isLoadingArticles: false,
      });
    // break;

    case types.LOADING_ARTICLES_FAILURE:
      return {
        ...state,
        errors: Object.assign({}, state.errors, {
          [action.category]: action.error,
        }),
        isLoadingArticles: false,
      };
    // break;

    default:
      return state;
  }
}
