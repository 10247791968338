// Vendor
import React, { Fragment, useState, useMemo } from 'react';
import cn from 'classnames';

import Dropdown from 'ui/Dropdown';
import DetailsMenu from './DetailsMenu';
import useProfile from 'hooks/useProfile';
import useAuth from 'features/auth/hooks/useAuth';
import useLogin from 'features/auth/hooks/useLogin';
import useAnalytics from 'hooks/useAnalytics';
import styles from './styles.styl';
import Tooltip from 'ui/Tooltip';

function Profile() {
  const {
    formData: { email },
  } = useProfile();
  const { isAuthenticated, logout } = useAuth();
  const { showFullscreenWidget } = useLogin();
  const { elementClick } = useAnalytics();
  const handleLoginClick = () => {
    showFullscreenWidget();
    elementClick('80lv_site_login-widget_open');
  };

  const [isPressed, setIsPressed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const tooltipId = useMemo(() => {
    return `show-email-0`;
  }, []);

  return (
    <Dropdown
      content={toggleDropdown => <DetailsMenu toggleDropdown={toggleDropdown} logout={logout} />}
      button={toggleDropdown => (
        <Fragment>
          {isAuthenticated ? (
            <div
              onClick={() => {
                toggleDropdown();
                setIsPressed(!isPressed);
              }}
              className={cn(styles.Profile__login)}
              onMouseEnter={() => {
                if (!isPressed) {
                  setIsHovered(true);
                }
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
              data-tip
              data-for={tooltipId}
            >
              <img
                src={`/static/icons/profile-loggedin${isPressed && !isHovered ? '-pressed' : ''}${
                  isHovered && !isPressed ? '-hovered' : ''
                }.svg`}
                alt="profile_loggedin"
                className={cn(styles.Profile__login__loggedin)}
              />
              <Tooltip id={tooltipId} effect="solid" place="right">
                {email}
              </Tooltip>
            </div>
          ) : (
            <div
              onClick={handleLoginClick}
              onMouseEnter={() => {
                if (!isPressed) {
                  setIsHovered(true);
                }
              }}
              onMouseLeave={() => {
                setIsHovered(false);
              }}
              className={cn(styles.Profile__login, {
                [styles.Profile__login_hovered]: isHovered,
              })}
            >
              <img
                src={`/static/icons/profile-login.svg`}
                alt="profile_login"
                className={cn(styles.Profile__login_icon)}
              />
              LogIn
            </div>
          )}
        </Fragment>
      )}
    />
  );
}

export default Profile;
