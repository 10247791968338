import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames/bind';

import AppLink from 'components/AppLink';
import Heading from 'components/Heading';
import Page from 'components/Page';
import AssetsSlider from 'components/AssetsSlider';

import styles from './styles.styl';

const cx = cn.bind(styles);

function NotFound({ featured }) {
  return (
    <div className={cx('NotFound')}>
      <Page.Section className={cx('NotFound__error')}>
        <div className={cx('NotFound__errorStatus')}>
          <img
            className={cx('NotFound__errorCode')}
            src="/static/images/errors/404.svg"
            alt="404_error"
          />
        </div>
        <div className={cx('NotFound__errorContent')}>
          <div className={cx('NotFound__row')}>
            <Heading className={cx('NotFound__errorTitle')} level={2}>
              Page Not Found
            </Heading>
          </div>
          <div className={cx('NotFound__row')}>
            You can return to the
            <AppLink href="/" className={cx('NotFound__link')}>
              main page
            </AppLink>
          </div>
          <div className={cx('NotFound__row')}>or look at the selected articles</div>
        </div>
      </Page.Section>
      {featured.items && Boolean(featured.items.length) && (
        <Page.Section className={cx('NotFound__featured')}>
          <AssetsSlider {...featured} />
        </Page.Section>
      )}
    </div>
  );
}

NotFound.propTypes = {
  featured: PropTypes.object,
};

export default NotFound;
