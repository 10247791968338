import { createSlice } from '@reduxjs/toolkit';
import data from './data.json';

const initialState = {
  ...data,
};

const aboutSlice = createSlice({
  name: 'about',
  initialState,
});

const { reducer } = aboutSlice;
export default reducer;
