import types from './types';

const initialState = {
  isLoading: false,
  isLoaded: false,
  formData: {
    city: '',
    state: '',
    country: '',
    name: '',
    surname: '',
    position: '',
    workStatus: false,
    workTypesSelected: [],
    image: null,
    email: '',
    rfp_show_status: true,
  },
  id: null,
  workTypes: [],
  avatar: '',
  error: {},
};

export default function profile(
  state = initialState,
  { error, formData, workTypes, avatar, id, type, ...loadStatuses },
) {
  switch (type) {
    case types.LOAD_PROFILE_REQUEST:
      return { ...state, ...loadStatuses };

    case types.SET_FORM_DATA:
      return {
        ...state,
        formData: {
          ...state.formData,
          image: null,
          ...formData,
        },
      };

    case types.SET_WORK_TYPES:
      return {
        ...state,
        workTypes,
      };

    case types.SET_AVATAR:
      return {
        ...state,
        avatar,
      };

    case types.SET_ID:
      return {
        ...state,
        id,
      };

    case types.LOAD_PROFILE_FAILURE:
      return { ...state, error, ...loadStatuses };

    default:
      return state;
  }
}
