/** Сниппет для рекламных баннеров
 * Резервирует баннеры с указанными id(добавляет ключ isReserved)
 * Логика вывода баннеров:
 * Есть позиции баннеров в которые пользователь может установить
 * любой баннер. У таких баннеров в content приходит ID.
 * Но на странице могут быть позиции в которые не установлен баннер
 * но они всеравно должны вывестись в зависимости от категории или вендора.
 * Эти баннеры достаются из хранилища и помечаются как зарезервированные.
 * Чтобы небыло повторений, баннеры из ids резервируются до записи в хранилище.
 *
 * @param {array} data - данные API
 * @param {array} ids - список id баннеров
 * @returns {array}
 */
export default function promosSnippet(data, ids = []) {
  return data && data.length
    ? data.map(promo =>
        ids.includes(promo.id) ? Object.assign(promo, { isReserved: true }) : promo,
      )
    : [];
}
