const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

const initialState = {
  isLoading: false,
  isLoaded: false,
  pages: {},
  error: {},
};

export const loadJobCard = createAsyncThunk(
  'jobCards/load',
  async (slug, { extra: httpService }) => {
    const jobCard = await httpService.get({ url: `/jobs/${slug}` });
    return jobCard;
  },
);

export const applyToJob = createAsyncThunk('jobCards/apply', async (id, { extra: httpService }) => {
  const response = await httpService.post({
    url: `/rfp/jobs/request/company`,
    data: { job_id: id },
  });
  return response.results;
});

const jobCardSlice = createSlice({
  name: 'jobCard',
  initialState,
  reducers: {},
  extraReducers: {
    [loadJobCard.pending]: state => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    [loadJobCard.fulfilled]: (state, { payload, meta: { arg: slug } }) => {
      state.pages[slug] = payload;
      state.isLoading = false;
      state.isLoaded = true;
    },
    [loadJobCard.rejected]: (state, { error }) => {
      state.isLoaded = false;
      state.isLoading = false;
      state.error = error;
    },
  },
});

const { reducer } = jobCardSlice;

export default reducer;
