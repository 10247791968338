/* eslint-disable */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import CloseButton from '../CloseButton';
import styles from './styles.styl';

export default ({ setRef }) => (
  <ToastContainer
    ref={setRef}
    toastClassName={styles.ToastContainer__toast}
    className={styles.ToastContainer}
    hideProgressBar={true}
    closeButton={<CloseButton />}
  />
);
