import immutable from 'object-path-immutable';
import types from './types';

const initialState = {
  pages: {},
  postComments: false,

  jobsList: {
    isLoading: false,
    total: 0,
    items: [],
  },

  errors: {
    article: null,
    comments: {
      form: null,
      items: null,
    },
  },
};

export default function job(state = initialState, action) {
  switch (action.type) {
    // Страница вакансии
    case types.LOAD_JOB_REQUEST:
      return immutable.set(state, ['pages', action.slug], {
        isLoading: true,
        isLoaded: false,
      });
    // break;

    case types.LOAD_JOB_SUCCESS:
      return immutable.assign(state, ['pages', action.slug], {
        ...action.job,
        isLoading: true,
        isLoaded: false,
      });
    // break;

    case types.LOAD_JOB_FAILURE:
      return Object.assign({}, state, {
        pages: immutable.set(state.pages, action.slug, { isLoading: false }),
        errors: action.error,
      });
    // break;

    case types.LOAD_JOBS_START:
      return Object.assign({}, state, {
        jobsList: {
          ...state.jobsList,
          isLoading: true,
        },
      });
    // break;

    case types.LOAD_JOBS_SUCCESS:
      return Object.assign({}, state, {
        jobsList: {
          isLoading: false,
          total: action.total,
          items: state.jobsList.items.concat(action.jobs),
        },
      });
    // break;

    case types.LOAD_JOBS_FAILURE:
      return Object.assign({}, state, {
        jobsList: {
          ...state.jobsList,
          isLoading: false,
          error: action.error,
        },
      });
    // break;

    case types.POST_COMMENT_START:
      return Object.assign({}, state, {
        postComments: true,
      });
    // break;

    case types.POST_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        postComments: false,
        pages: immutable.update(state.pages, [action.slug, 'comments'], comments =>
          Object.assign({}, comments, {
            total: action.total,
            items: [action.comment, ...comments.items],
          }),
        ),
        errors: immutable.set(state.errors, ['comments', 'form'], null),
      });
    // break;

    case types.POST_COMMENT_FAILURE:
      return Object.assign({}, state, {
        postComments: false,
        errors: immutable.set(state.errors, ['comments', 'form'], action.error),
      });
    // break;

    case types.LOAD_COMMENTS_START:
      return Object.assign({}, state, {
        pages: immutable.assign(state.pages, [action.slug, 'comments'], {
          isLoading: true,
          isLoaded: false,
        }),
        errors: immutable.set(state.errors, ['comments', 'items'], null),
      });
    // break;

    case types.LOAD_COMMENTS_SUCCESS:
      return immutable.update(state, ['pages', action.slug, 'comments'], comments => {
        return Object.assign({}, comments, {
          items: [...comments.items, ...action.comments],
          isLoading: false,
          isLoaded: true,
        });
      });
    // break;

    case types.LOAD_COMMENTS_FAILURE:
      return Object.assign({}, state, {
        pages: immutable.assign(state.pages, [action.slug, 'comments'], {
          isLoading: false,
        }),
        errors: immutable.set(state.errors, ['comments', 'items'], action.error),
      });
    // break;

    case types.COMMENTS_CLEAR_ERROR_FORM:
      return immutable.set(state, ['errors', 'comments', 'form'], null);
    // break;

    case types.SEND_LIKE_START:
      return immutable.assign(
        state,
        ['pages', action.slug, 'comments', 'items', action.commentIndex],
        {
          isVoting: true,
          isVoted: false,
          error: '',
        },
      );
    // break;

    case types.SEND_LIKE_SUCCESS:
      return immutable.assign(
        state,
        ['pages', action.slug, 'comments', 'items', action.commentIndex],
        {
          isVoting: false,
          isVoted: true,
          karma: action.karma,
          like: action.like,
          error: '',
        },
      );
    // break;

    case types.SEND_LIKE_FAILURE:
      return immutable.assign(
        state,
        ['pages', action.slug, 'comments', 'items', action.commentIndex],
        {
          isVoting: false,
          isVoted: false,
          error: action.error,
        },
      );
    // break;

    default:
      return state;
    // break;
  }
}
