/* eslint-disable */
import React from 'react';
import animejs from 'animejs';
import Transition from 'react-transition-group/Transition';
import PropTypes from 'prop-types';

const DUR = 400;

const ToggleButton = ({ in: inProp, children }) => {
  function onEnter(node) {
    const duration = DUR;

    animejs.remove(node);
    animejs.set(node, { opacity: 0, width: 0 });

    animejs({
      targets: node,
      duration: duration * 1.2,
      width: [0, node.scrollWidth],
      easing: 'easeOutQuad',
      begin: () => {
        animejs({
          targets: node,
          duration: duration / 2,
          opacity: [0, 1],
          easing: 'easeOutQuad',
          delay: duration / 2,
        });
      },
    });
  }

  function onExit(node) {
    const duration = DUR;
    animejs.remove(node);

    animejs({
      targets: node,
      duration: duration,
      opacity: 0,
      easing: 'easeOutQuad',
      begin: () => {
        animejs({
          targets: node,
          duration: duration / 2,
          width: 0,
          easing: 'easeOutQuad',
          delay: duration / 2,
        });
      },
    });
  }

  function onEntered(node) {
    node.setAttribute('style', '');
  }

  return (
    <Transition
      in={inProp}
      timeout={DUR}
      onEnter={onEnter}
      onExit={onExit}
      onEntered={onEntered}
      unmountOnExit
      mountOnEnter
    >
      {children}
    </Transition>
  );
};

ToggleButton.propTypes = {
  inProp: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

export default ToggleButton;
