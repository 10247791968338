/**
 * Simple debounce function
 * @param {func} func
 * @param {number} timeout in ms
 */

export const debounce = (func, timeout) => {
  let timeoutId = null;
  let lastCall = null;

  return () => {
    let prevCall = lastCall;

    if (prevCall && lastCall - prevCall <= timeout) {
      clearTimeout(timeoutId);
    }

    lastCall = Date.now();
    timeoutId = setTimeout(() => func(), timeout);
  };
};
