export const customizationSelector = state => state.customization;

/**
 *
 * @param {*} state
 * @returns {{
 *  isLoaded: boolean,
 *  isLoading: boolean,
 *  background_color: string;
 *  color: string;
 *  text: string;
 *  href: string;
 * }}
 */
export const headerButtonSelector = state => customizationSelector(state).headerButton;
/**
 *
 * @param {*} state
 * @returns {{
 *  isLoaded: boolean,
 *  isLoading: boolean,
 *  background_color?: string,
 *  background_image?: string;
 *  text?: string;
 *  color?: string;
 *  button?: {
 *      background_color: string;
 *      color: string;
 *      text: string;
 *      href: string;
 *  }
 * }}
 */
export const sideWidgetSelector = state => customizationSelector(state).sideWidget;
/**
 * @param {*} state
 * @returns {{
 *  isLoaded: boolean;
 *  isLoading: boolean
 *  background_color?: string;
 *  background_image?: string;
 *  color?: string;
 *  text?: string;
 *  button?: {
 *      background_color: string;
 *      color: string;
 *      href: string;
 *      text: string;
 *   }
 *  }}
 */
export const barWidgetSelector = state => customizationSelector(state).barWidget;
