// Vendor
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { createURL } from 'utils/url';

// Components
import Fade from 'components/Animations/Fade';
import AppLink from 'components/AppLink';

import HideIcon from 'static/icons/close.svg';

import styles from './styles.styl';

function renderTag({ tag, key, url, assetsPath, tagClassName, withoutLink, queryName }) {
  const isLink = Boolean(tag.slug);
  const href = assetsPath
    ? createURL(assetsPath, { slug: tag.slug })
    : createURL(url, { [queryName]: tag.slug });
  const as = assetsPath ? `${url}/${tag.slug}` : null;

  return (
    <li key={`${tag.name}-${key}`} className={cn(styles.Tags__tag, tagClassName)}>
      {!withoutLink && isLink && (
        <AppLink href={href} as={as} className={styles.Tags__tagLink}>
          {tag.name}
        </AppLink>
      )}
      {(withoutLink || !isLink) && tag.name}
    </li>
  );
}

function Tags({
  className,
  size,
  align,
  tags,
  theme,
  url,
  assetsPath,
  tagClassName,
  withoutLink = false,
  queryName = 'category',
}) {
  const listRef = useRef(null);
  const [isLoaded, setLoaded] = useState(true);
  const [isShow, setShowTags] = useState(false);
  const [visibleTags, setVisibleTags] = useState(tags);
  const [hiddenCount, setHiddenCount] = useState(0);
  const [hiddenTags, setHiddenTags] = useState([]);
  const buttonText = isShow ? <HideIcon /> : `+${hiddenCount}`;

  const contentWidth = useRef(0);
  useEffect(() => {
    function getHiddenTags() {
      const tagsList = listRef.current.children;
      const container = listRef.current.parentNode;
      const containerWidth = container.clientWidth;

      if (contentWidth.current !== containerWidth) {
        setLoaded(false);

        for (let i = 0; i < tagsList.length; i++) {
          const tagElement = tagsList[i];
          const tagElementWidth = tagElement.clientWidth;
          const isLastItem = i === tagsList.length - 1;
          if (tagElementWidth + (isLastItem ? 0 : 57) + tagElement.offsetLeft > containerWidth) {
            setHiddenCount(tags.length - i);
            setHiddenTags(tags.slice(i));
            setVisibleTags(tags.slice(0, i));

            break;
          } else if (isLastItem) {
            setHiddenCount(0);
            setVisibleTags(tags);
          }
        }

        contentWidth.current = containerWidth;
        setLoaded(true);
      }
    }

    if (tags.length) {
      window.addEventListener('resize', getHiddenTags);
      getHiddenTags();
    }

    return () => {
      window.removeEventListener('resize', getHiddenTags);
    };
  }, [tags]);

  return !tags.length ? null : (
    <div
      className={cn(styles.Tags, className, {
        [styles.Tags_open]: isShow,
        [styles[`Tags_${theme}`]]: !!theme,
        [styles[`Tags_${align}`]]: align,
        [styles.Tags_loaded]: isLoaded,
        [styles[`Tags_${size}Size`]]: size,
      })}
    >
      <div className={styles.Tags__listWrapper}>
        <ul className={styles.Tags__list}>
          {visibleTags.map((tag, i) =>
            renderTag({ tag, i, url, assetsPath, tagClassName, withoutLink, queryName }),
          )}

          {Boolean(hiddenCount) && (
            <li
              className={cn(styles.Tags__tag, styles.Tags__tag_counter, tagClassName)}
              onClick={() => setShowTags(!isShow)}
            >
              {buttonText}
            </li>
          )}
        </ul>

        <ul ref={listRef} className={cn(styles.Tags__list, styles.Tags__list_hidden)}>
          {tags.map((tag, i) =>
            renderTag({ tag, i, url, assetsPath, tagClassName, withoutLink, queryName }),
          )}
        </ul>
      </div>

      <Fade in={isShow} duration={300} animationHeight>
        <ul className={cn(styles.Tags__list, styles.Tags__list_full)}>
          {hiddenTags.map((tag, i) =>
            renderTag({ tag, i, url, assetsPath, tagClassName, withoutLink, queryName }),
          )}
        </ul>
      </Fade>
    </div>
  );
}

Tags.propTypes = {
  className: PropTypes.string,
  tagClassName: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small']),
  align: PropTypes.oneOf(['left', 'right']),
  theme: PropTypes.oneOf(['light', 'dark', 'green']),
  tags: PropTypes.array.isRequired,
  url: PropTypes.string,
  withoutLink: PropTypes.bool,
  assetsPath: PropTypes.string,
  queryName: PropTypes.string,
};

Tags.defaultProps = {
  className: '',
  tagClassName: '',
  theme: 'light',
  align: 'left',
  tags: [],
  url: '/articles',
  assetsPath: null,
};

export default Tags;
