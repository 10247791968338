/* eslint-disable */
import React from 'react';
import cn from 'classnames';

// Components
import Arrow from 'static/icons/arrow.svg';
import Button from 'components/Button';

import styles from './styles.styl';

function CounterArrows({ onPrev, onNext, current, total }) {
  return (
    <div className={styles.CounterArrows}>
      <Button
        className={cn(styles.CounterArrows__button, styles.CounterArrows__button_prev)}
        onClick={onPrev}
        theme="none"
      >
        <span className={styles.CounterArrows__arrow}>
          <Arrow />
        </span>
      </Button>
      <span className={styles.CounterArrows__counter}>
        {current} of {total}
      </span>
      <Button
        className={cn(styles.CounterArrows__button, styles.CounterArrows__button_next)}
        onClick={onNext}
        theme="none"
      >
        <span className={styles.CounterArrows__arrow}>
          <Arrow />
        </span>
      </Button>
    </div>
  );
}

export default CounterArrows;
